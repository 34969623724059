import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  purchasePattern: [],
  addData: {},
  addDataHomeDashboardDataItem: {},
  addHomeDashboardDataData: {},
  drawerOpeningStatus: {},
  HomeDashboardDataDrawerData: {},
  HomeDashboardData: [],
  allData: [],
  sorted: [],
  saleTypes: [],
  viewHomeDashboardDataStatus: false,
  viewHomeDashboardDataCloseStatus: false,
};

const slice = createSlice({
  name: 'dashboardPurchasing',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },
    updateHomeDashboardDataStatus(state, value) {
      state.viewHomeDashboardDataStatus = value.payload;
    },
    updateHomeDashboardDataCloseStatus(state, value) {
      state.viewHomeDashboardDataCloseStatus = value.payload;
    },
    getAllSaleTypesSuccess(state, action) {
      state.saleTypes = action.payload.data;
    },
    getDrawerOpeningStatus(state, action) {
      state.drawerOpeningStatus = action.payload.data;
    },
    getHomeDashboardDataDrawerData(state, action) {
      state.HomeDashboardDataDrawerData = action.payload.data;
    },
    getIngredientPurchasePatternSuccess(state, action) {
      state.purchasePattern = action.payload.data;
    },
    getHomeDashboardDataGlobalSuccess(state, action) {
      state.globalData = action.payload.data;
    },
    getAllHomeDashboardDataData(state, action) {
      state.allData = action.payload.data;
    },
    addOrUpdateHomeDashboardData(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    addOrUpdateHomeDashboardDataItemStatus(state, action) {
      state.addDataHomeDashboardDataItem = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    clearAddOrUpdateHomeDashboardData(state) {
      state.addData = { data: null, time: moment.now().toString() };
    },
    clearAll(state) {
      state.addData = {};
      state.error = {};
    },
    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },
    onNextStep(state) {
      state.checkout.activeStep += 1;
    },
    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

export default slice.reducer;

export function getDashboardPurchasing(data) {
  return async () => {
    try {
      const response = await get(`dashboard/purchase/getIngredientPurchasePattern`, data);
      if (response.status) {
        dispatch(slice.actions.getIngredientPurchasePatternSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
