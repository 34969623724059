import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from '../../../../../../redux/store';
import { useEffect, useState } from 'react';
import { Grid, SwipeableDrawer, TextField } from '@mui/material';
import MenuItemBlock from './menuItemBlock';
import { safeJSONParse } from '../../../../../../utils/common';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other} sx={{ textAlign: 'left', width: '100%' }}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MenuList({ data, setData, onClickSound, onChangeTab }) {
  const { data: foodCategoryData } = useSelector((state) => state?.foodCategory);
  const { foodMenuData, foodVariationData } = useSelector((state) => state?.food);
  const [value, setValue] = React.useState(0);
  const [searchKeyWord, setSearchKeyWord] = React.useState('');
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [processedFoodMenuData, setProcessedFoodMenuData] = React.useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [variationOfSelectedFood, setVariationOfSelectedFood] = useState([]);
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  useEffect(() => {
    setCategoriesList([...foodCategoryData, { description: 'All', id: 0, name: 'All', type: 'all' }]);
  }, [foodCategoryData]);

  useEffect(() => {
    if (foodMenuData && foodVariationData) {
      const updatedMenuData = foodMenuData
        .filter((tempValue) => tempValue?.name?.toLowerCase()?.includes(searchKeyWord.toLowerCase()))
        .map((value) => ({
          ...value,
          pricingData: safeJSONParse(value?.pricingData || '{}'),
          recipeData: safeJSONParse(value?.recipeData || '{}'),
          locationData: safeJSONParse(value?.locationData || '{}'),
          variation: safeJSONParse(value?.variation || '{}'),
          variationData: safeJSONParse(value?.variation || '[]')
            .map((variationData) => foodVariationData.find((variationSearchIndex) => variationSearchIndex?.id === variationData?.variationId))
            .map((variationData) => ({
              ...variationData,
              pricingData: safeJSONParse(variationData?.pricingData || '{}'),
              recipeData: safeJSONParse(variationData?.recipeData || '{}'),
              locationData: safeJSONParse(variationData?.locationData || '{}'),
              variation: safeJSONParse(variationData?.variation || '{}'),
              variationData: safeJSONParse(variationData?.variation || '[]'),
            })),
        }));
      console.log('updatedMenuData', updatedMenuData);

      const filteredData = updatedMenuData?.filter(value=> {
        const locationArray = Array.isArray(value?.locationData) ? value.locationData.map(location => location?.locationID) : [];
        return locationArray.includes(selectedLocation?.id) || value.variationData?.length > 0;
      })
      console.log('filteredData: ', filteredData);
      const filteredData2 = filteredData?.map(value=> {
        return {
          ...value,
          variationData:  value?.variationData?.filter(value=> {
            const locationArray = Array.isArray(value?.locationData) ? value.locationData.map(location => location?.locationID) : [];
            return locationArray.includes(selectedLocation?.id) || locationArray.variationData?.length > 0;
          })
        }
      })
      console.log('filteredData2: ', filteredData2);

      const filteredData3 = filteredData2?.filter(value=> {
        return (value?.isVariation && value?.variationData?.length > 0) || !value?.isVariation;
      });
      console.log('filteredData3: ', filteredData3);
      setProcessedFoodMenuData(filteredData3);
    }
  }, [foodMenuData, searchKeyWord, foodVariationData, selectedLocation]);

  const addToCart = (item) => {
    setData((value) => {
      let valueToSet = [];

      const existingItem = value.orderInfo.cart.find((value) => item.id === value.id);

      if (existingItem) {
        valueToSet = value.orderInfo.cart.map((cartItem) =>
          cartItem.id === item.id
            ? {
                ...cartItem,
                quantity: cartItem.quantity + 1,
              }
            : cartItem
        );
      } else {
        valueToSet = [...value.orderInfo.cart, { ...item, quantity: 1 }];
      }

      return {
        ...value,
        orderInfo: { cart: valueToSet },
      };
    });
  };

  return (
    <>
      <Grid item xs={12} md={2}>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={value}
          onChange={(event, newValue) => {
            onClickSound();
            setValue(newValue);
          }}
          aria-label='Vertical tabs'
          sx={{ textAlign: 'left', borderRight: 1, borderColor: 'divider' }}
        >
          {categoriesList?.map((category, index) => (
            <Tab key={index} label={category?.name} />
          ))}
        </Tabs>
      </Grid>

      <Grid item xs={12} md={10}>
        <TextField
          type='text'
          variant='standard'
          label={`Search For Product`}
          sx={{ width: '90%', marginLeft: '5%' }}
          placeholder={`Enter your menu item here...`}
          value={data?.phone}
          onChange={(event) => {
            onClickSound();
            setValue(0);
            setSearchKeyWord(event.target.value);
          }}
        />
        {categoriesList?.map((category, index) => (
          <TabPanel key={index} value={value} index={index}>
            <Grid container justifyContent='center'>
              {processedFoodMenuData?.map(
                (menuData, menuIndex) =>
                  (category?.type === 'all' || menuData?.foodCategoryId === category?.id) && (
                    <Grid item key={menuIndex} md={3}>
                      <MenuItemBlock
                        title={menuData?.name}
                        code={menuData?.code}
                        image={menuData?.image}
                        price={menuData?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id)?.pricing}
                        isVariation={menuData?.isVariation === 1}
                        onClickSound={onClickSound}
                        variationMethod={() => {
                          setIsModelOpen(true);
                          setVariationOfSelectedFood(menuData?.variationData);
                        }}
                        addToCart={() => {
                          addToCart(menuData);
                        }}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </TabPanel>
        ))}
        <SwipeableDrawer
          anchor={'right'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '60%',
              height: '90%',
              marginTop: '3%',
              borderRadius: '10px 0 0 10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h2>Select your variation</h2>
          </Box>
          <Grid container justifyContent='left' p={3}>
            {variationOfSelectedFood?.map((menuData, menuIndex) => (
              <Grid item key={menuIndex} md={3}>
                <MenuItemBlock
                  title={menuData?.name}
                  code={menuData?.code}
                  image={menuData?.image}
                  onClickSound={onClickSound}
                  price={menuData?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id)?.pricing}
                  isVariation={menuData?.isVariation === 1}
                  variationMethod={() => {}}
                  addToCart={() => {
                    setIsModelOpen(false);
                    addToCart(menuData);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </SwipeableDrawer>
      </Grid>
    </>
  );
}
