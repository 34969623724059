import moment from 'moment';
import { safeJSONParse, utcMoment } from '../../../../../utils/common';
import { fCurrency } from '../../../../../utils/formatNumber';

export const getTotalSaleAmount = (data) => {
  return data?.reduce((acc, curr) => acc + curr?.pos_totalAmount, 0) || 0;
};
export const getTotalNumberOfSales = (data) => {
  return data?.length || 0;
};

export const getDiningCustomerOrderCount = (data) => {
  return data?.filter((value) => [1].includes(value?.pos_saleTypeId))?.length || 0;
};

export const getTakeAwayCustomerOrderCount = (data) => {
  return data?.filter((value) => [2].includes(value?.pos_saleTypeId))?.length || 0;
};

export const getPercentage = (total, value) => {
  return ((value / total) * 100).toFixed(2);
};

export const getAverageKotValues = (data, globalValue) => {
  const uniquePhones = new Set();
  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });
  const averageValue = {};
  const customerListLocation = {};

  filteredData?.forEach((value) => {
    if (averageValue[value?.pos_locationName]) {
      averageValue[value?.pos_locationName] += value?.pos_totalAmount;
      if (!customerListLocation[value?.pos_locationName].includes(value?.customerPhone)) {
        customerListLocation[value?.pos_locationName].push(value?.customerPhone);
      }
    } else {
      averageValue[value?.pos_locationName] = value?.pos_totalAmount;
      customerListLocation[value?.pos_locationName] = [value?.customerPhone];
    }
  });

  return (
    <>
      <p>
        <span style={{ fontWeight: 800 }}>Global: </span>
        <span style={{ fontWeight: 500 }}>{fCurrency(globalValue)}</span>
      </p>
      {Object.keys(averageValue).map((key) => (
        <p key={key}>
          <span style={{ fontWeight: 800 }}>{key}: </span>
          <span style={{ fontWeight: 500 }}>{fCurrency(averageValue[key] / customerListLocation[key].length)}</span>
        </p>
      ))}
    </>
  );
};

export const getAverageNewCustomerSale = (data, globalData) => {
  const uniquePhones = new Set();
  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });

  const uniquePhonesForGlobal = new Set();
  const filteredDataForGlobal = globalData
    ?.filter((value) => !utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')))
    ?.filter((value) => {
      const phone = value?.customerPhone;
      if (phone && !uniquePhonesForGlobal.has(phone) && !['94770884019'].includes(phone)) {
        uniquePhonesForGlobal.add(phone);
        return [1, 2].includes(value?.pos_saleTypeId);
      }
      return false;
    });

  const averageValue = {};
  const customerListLocation = {};

  filteredData
    ?.filter((value) => !utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')))
    ?.forEach((value) => {
      if (averageValue[value?.pos_locationName]) {
        averageValue[value?.pos_locationName] += value?.pos_totalAmount;
        if (!customerListLocation[value?.pos_locationName].includes(value?.customerPhone)) {
          customerListLocation[value?.pos_locationName].push(value?.customerPhone);
        }
      } else {
        averageValue[value?.pos_locationName] = value?.pos_totalAmount;
        customerListLocation[value?.pos_locationName] = [value?.customerPhone];
      }
    });

  const returningCustomerCountForGlobal = filteredDataForGlobal?.filter((value) => !utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')));
  const globalSale = filteredDataForGlobal?.reduce((acc, curr) => acc + curr?.pos_totalAmount, 0) || 0;
  return (
    <>
      <p>
        <span style={{ fontWeight: 800 }}>Global: </span>
        <span style={{ fontWeight: 500 }}>{fCurrency(globalSale / returningCustomerCountForGlobal?.length || 0)}</span>
      </p>
      {Object.keys(averageValue).map((key) => (
        <p key={key}>
          <span style={{ fontWeight: 800 }}>{key}: </span>
          <span style={{ fontWeight: 500 }}>{fCurrency(averageValue[key] / customerListLocation[key]?.length || 0)}</span>
        </p>
      ))}
    </>
  );
};

export const getAverageReturningCustomerSale = (data, globalData) => {
  const uniquePhones = new Set();
  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });

  const uniquePhonesForGlobal = new Set();
  const filteredDataForGlobal =
    globalData
      ?.filter((value) => utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')))
      ?.filter((value) => {
        const phone = value?.customerPhone;
        if (phone && !uniquePhonesForGlobal.has(phone) && !['94770884019'].includes(phone)) {
          uniquePhonesForGlobal.add(phone);
          return [1, 2].includes(value?.pos_saleTypeId);
        }
        return false;
      }) || [];

  const averageValue = {};
  const customerListLocation = {};

  filteredData
    ?.filter((value) => utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')))
    ?.forEach((value) => {
      if (averageValue[value?.pos_locationName]) {
        averageValue[value?.pos_locationName] += value?.pos_totalAmount;
        if (!customerListLocation[value?.pos_locationName].includes(value?.customerPhone)) {
          customerListLocation[value?.pos_locationName].push(value?.customerPhone);
        }
      } else {
        averageValue[value?.pos_locationName] = value?.pos_totalAmount;
        customerListLocation[value?.pos_locationName] = [value?.customerPhone];
      }
    });

  const returningCustomerCountForGlobal = filteredDataForGlobal?.filter((value) => utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')));
  const globalSale = filteredDataForGlobal?.reduce((acc, curr) => acc + curr?.pos_totalAmount, 0) || 0;
  return (
    <>
      <p>
        <span style={{ fontWeight: 800 }}>Global: </span>
        <span style={{ fontWeight: 500 }}>{fCurrency(globalSale / returningCustomerCountForGlobal?.length)}</span>
      </p>
      {Object.keys(averageValue).map((key) => (
        <p key={key}>
          <span style={{ fontWeight: 800 }}>{key}: </span>
          <span style={{ fontWeight: 500 }}>{fCurrency(averageValue[key] / customerListLocation[key]?.length || 0)}</span>
        </p>
      ))}
    </>
  );
};
export const getAverageReturningCustomerForLocation = (data, globalValue) => {
  const uniquePhones = new Set();
  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });
  const returningCustomerCount = {};
  const walkingCustomerCount = {};

  filteredData?.forEach((value) => {
    if (utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day'))) {
      if (returningCustomerCount[value?.pos_locationName]) {
        returningCustomerCount[value?.pos_locationName] += 1;
      } else {
        returningCustomerCount[value?.pos_locationName] = 1;
      }
    }
    if (walkingCustomerCount[value?.pos_locationName]) {
      walkingCustomerCount[value?.pos_locationName] += 1;
    } else {
      walkingCustomerCount[value?.pos_locationName] = 1;
    }
  });
  return (
    <>
      <p>
        <span style={{ fontWeight: 800 }}>Global: </span>
        <span style={{ fontWeight: 500 }}>{globalValue}%</span>
      </p>
      {Object.keys(returningCustomerCount).map((key) => (
        <p key={key}>
          <span style={{ fontWeight: 800 }}>{key}: </span>
          <span style={{ fontWeight: 500 }}>{((returningCustomerCount[key] / walkingCustomerCount[key]) * 100).toFixed(2)}%</span>
        </p>
      ))}
    </>
  );
};

export const getReturnWalkingCustomerCount = (data) => {
  const uniquePhones = new Set();

  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });
  return (
    filteredData?.filter((value) => [1, 2].includes(value?.pos_saleTypeId) && utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')))?.length || 0
  );
};

export const getWalkingCustomerCount = (data) => {
  const uniquePhones = new Set();

  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });

  return filteredData?.length || 0;
};

export const getPickMeOrderCount = (data) => {
  return data?.filter((value) => [3].includes(value?.pos_saleTypeId))?.length || 0;
};

export const getUberOrderCount = (data) => {
  return data?.filter((value) => [4].includes(value?.pos_saleTypeId))?.length || 0;
};

const getDateRangeFromSalesData = (salesData) => {
  const sortedData = salesData.slice().sort((a, b) => new Date(a.pos_dateTime) - new Date(b.pos_dateTime));

  const startDate = moment.utc(sortedData[0]?.pos_dateTime);
  const endDate = moment.utc(sortedData[sortedData.length - 1]?.pos_dateTime);

  const daysDifference = Math.ceil(endDate.diff(startDate, 'hour') / 24);

  return { startDate, endDate, daysDifference };
};

export const getHourlySalesDateBased = (salesData) => {
  const { startDate, endDate } = getDateRangeFromSalesData(salesData);

  const totalHours = endDate.diff(startDate, 'hours');

  const hourlySales = Array.from({ length: totalHours + 1 }, (_, index) => ({
    dateTime: startDate.clone().add(index, 'hours').format('DD MMMM HH:00'),
    totalAmount: 0,
    totalSales: 0,
  }));

  salesData.forEach((sale) => {
    const saleDateTime = moment.utc(sale?.pos_dateTime);
    const hourIndex = saleDateTime.diff(startDate, 'hours');
    if (hourIndex >= 0 && hourIndex <= totalHours) {
      hourlySales[hourIndex].totalAmount += sale?.pos_totalAmount || 0;
      hourlySales[hourIndex].totalSales += 1;
    }
  });
  return {
    xAxis: hourlySales?.map((value) => value?.dateTime) || [],
    series: [
      {
        label: 'Total Amount',
        data: hourlySales?.map((value) => value?.totalAmount) || [],
      },
    ],
  };
};
export const getHourlySalesHourBased = (salesData) => {
  const hourlySales = Array.from({ length: 24 }, (_, index) => ({
    dateTime: index + 1,
    totalAmount: 0,
    totalSales: 0,
  }));

  salesData.forEach((sale) => {
    const hour = utcMoment(sale?.pos_dateTime).format('HH');
    if (hourlySales[hour]) {
      hourlySales[hour].totalAmount += sale?.pos_totalAmount || 0;
      hourlySales[hour].totalSales += 1;
    }
  });
  return {
    xAxis: hourlySales.map((value) => value.dateTime),
    series: [
      {
        name: 'Total Amount',
        data: hourlySales.map((value) => value.totalAmount),
      },
    ],
  };
};

export const getPaymentTypes = (data) => {
  const eachPaymentTypeAmounts = [];

  data?.forEach((value) => {
    value?.pos_payments?.forEach((payment) => {
      const paymentType = payment?.paymentTypesName;
      const paymentAmount = payment?.amount;
      const paymentIndex = eachPaymentTypeAmounts.findIndex((value) => value?.paymentType === paymentType);
      if (paymentIndex === -1) {
        eachPaymentTypeAmounts.push({
          paymentType,
          paymentAmount,
        });
      } else {
        eachPaymentTypeAmounts[paymentIndex].paymentAmount += paymentAmount;
      }
    });
  });
  return eachPaymentTypeAmounts?.map((data) => ({ value: data?.paymentAmount, label: data?.paymentType })) || [];
};

export const getPaymentTypeForValue = (data, key) => {
  const eachPaymentTypeAmounts = [];

  data?.forEach((value) => {
    value?.pos_payments?.forEach((payment) => {
      const paymentType = payment?.paymentTypesName;
      const paymentAmount = payment?.amount;
      const paymentIndex = eachPaymentTypeAmounts.findIndex((value) => value?.paymentType === paymentType);
      if (paymentIndex === -1) {
        eachPaymentTypeAmounts.push({
          paymentType,
          paymentAmount,
        });
      } else {
        eachPaymentTypeAmounts[paymentIndex].paymentAmount += paymentAmount;
      }
    });
  });
  return eachPaymentTypeAmounts?.find((data) => data?.paymentType === key)?.paymentAmount || 0;
};

export const getItemWiseSales = (data, type) => {
  const eachItemAmounts = [];
  data?.forEach((value) => {
    value?.pos_items?.forEach((item) => {
      const itemName = item?.foodName;
      const itemAmount = item?.qty;
      const itemIndex = eachItemAmounts.findIndex((value) => value?.itemName === itemName);
      if (itemIndex === -1) {
        eachItemAmounts.push({
          itemName,
          itemAmount,
        });
      } else {
        eachItemAmounts[itemIndex].itemAmount += itemAmount;
      }
    });
  });
  const sortedByValue = eachItemAmounts?.map((data) => ({ value: data?.itemAmount, label: data?.itemName }))?.sort((a, b) => b.value - a.value) || [];
  if (type === 'bar') {
    return {
      xAxis: [{ scaleType: 'band', data: sortedByValue?.map((value) => value?.label) || [] }],
      series: [
        {
          label: 'Total Count',
          data: sortedByValue?.map((value) => value?.value) || [],
        },
      ],
    };
  } else if (type === 'pie') {
    return sortedByValue?.map((value) => ({ value: value?.value, label: value?.label })) || [];
  } else if (type === 'table') {
    return sortedByValue?.map((value) => ({ Name: value?.label, qty: value?.value })) || [];
  }
};

const removeDuplicates = (data) => {
  const uniquePhones = new Set();
  return data?.filter((item) => {
    if (item?.customerPhone && !uniquePhones.has(item.customerPhone)) {
      uniquePhones.add(item.customerPhone);
      return true;
    }
    return false;
  });
};
export const getAverageWalkingCustomerSaleSpentByCustomer = (data) => {
  const uniquePhones = new Set();
  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return [1, 2].includes(value?.pos_saleTypeId);
    }
    return false;
  });
  const uniqueCustomerListCount = removeDuplicates(filteredData)?.length;
  const sumOfFilteredData = filteredData?.reduce((acc, curr) => acc + curr?.pos_totalAmount, 0) || 0;
  return sumOfFilteredData / uniqueCustomerListCount || 0;
};

export const getTotalSaleOfSaleType = (data, type) => {
  const uniquePhones = new Set();
  const filteredData = data?.filter((value) => {
    const phone = value?.customerPhone;
    if (phone && !uniquePhones.has(phone) && !['94770884019'].includes(phone)) {
      uniquePhones.add(phone);
      return type.includes(value?.pos_saleTypeId);
    }
    return false;
  });
  const sumOfFilteredData = filteredData?.reduce((acc, curr) => acc + curr?.pos_totalAmount, 0) || 0;
  return sumOfFilteredData || 0;
};

export const getStatusCounts = (data, type) => {
  return data?.filter((item) => item.pos_status === type).length;
};

export const getReturningCustomerAmounts = (data, globalData, selectedLocationId, type) => {
  const uniquePhonesForGlobal = new Set();
  const filteredDataForGlobal =
    globalData
      ?.filter((value) => utcMoment(value?.customerJoinDate).startOf('day').isBefore(utcMoment(value?.pos_dateTime).startOf('day')))
      ?.filter((value) => {
        const phone = value?.customerPhone;
        if (phone && !uniquePhonesForGlobal.has(phone) && !['94770884019'].includes(phone)) {
          uniquePhonesForGlobal.add(phone);
          return type.includes(value?.pos_saleTypeId) && (Number(value?.pos_locationId) === Number(selectedLocationId) || selectedLocationId === 1);
        }
        return false;
      }) || [];
  const depositedSale = filteredDataForGlobal?.map(value=> {
    let depositSale = 0
    const paymentData = safeJSONParse(value?.pos_payments);
    depositSale = paymentData?.map(payment => {
      let depositAmount = 0;
      switch (payment.paymentTypesName) {
        case 'Cash':
          depositAmount = payment.amount;
          break;
        case 'Other Cards':
          depositAmount = payment.amount * 0.97;
          break;
        case 'Amex Cards':
          depositAmount = payment.amount * 0.97;
          break;
        case 'Pickme Food':
          depositAmount = payment.amount * 0.66;
          break;
        case 'Uber Eats':
          depositAmount = payment.amount * 0.66;
          break;
      }
      return {
        ...payment,
        depositAmount
      }
    })
    return {...
      value,
      depositedSale:depositSale?.reduce((acc, curr) => acc + curr?.depositAmount, 0)
    }
  })
  return depositedSale?.reduce((acc, curr) => acc + curr?.depositedSale, 0);
};