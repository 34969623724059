import { styled, useTheme } from '@mui/material/styles';
import { Card, Chip, FormControl, Grid, TextField, Autocomplete } from '@mui/material';
import { MobileDateRangePicker } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { format, startOfDay, endOfDay } from 'date-fns';
import moment from 'moment/moment';
import { accessVerify } from '../../../../../utils/common';
import { getDashboardPurchasing } from '../../../../../redux/slices/homeRedux/dashboardPurchasingRedux';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({}));

export default function DataFilter({ data, setData, setGlobalData, setOriginalData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let usersListData;
  ({ data: usersListData } = useSelector((state) => state.user));
  let purchasePattern;
  ({ purchasePattern: purchasePattern } = useSelector((state) => state.dashboardPurchasing));

  const [openPicker, setOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState([startOfDay(new Date("2023-04-01 00:00:00")), endOfDay(new Date())]);
  const [selectedFood, setSelectedFood] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);

  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  useEffect(() => {

    const startDate = moment(dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    dispatch(
      getDashboardPurchasing({
        fromDate: startDate,
        toDate: endDate,
        locationId: selectedLocation?.id,
      })
    );
  }, [dateRange, selectedLocation]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (purchasePattern) {
      setData(purchasePattern);
      const uniqueFoods = Array.from(
        new Map(purchasePattern.map((item) => [item.foodName, item])).values()
      );
      setFilteredFoods(uniqueFoods);
    }
  }, [purchasePattern]);

  useEffect(() => {
    if (selectedFood.length > 0) {
      setData(purchasePattern?.filter(value=> selectedFood?.includes(value.foodId)));
    } else {
      setData(purchasePattern);
    }
  }, [selectedFood, purchasePattern]);

  const formatDateRangeDisplay = ([start, end]) => {
    if (!start || !end) return '';
    return `${format(start, 'dd/MM/yyyy')} - ${format(end, 'dd/MM/yyyy')}`;
  };

  return (
    <>
      {accessVerify('DASHBOARD_ANALYTICS_DATE_SELECTION') && (
        <Grid item xs={12} md={6}>
          <MobileDateRangePicker
            open={openPicker}
            onClose={() => setOpenPicker(false)}
            onOpen={() => setOpenPicker(true)}
            value={dateRange}
            onChange={(newValue) => {}}
            onAccept={(newValue) => {
              setDateRange(newValue);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <TextField
                ref={inputRef}
                {...inputProps}
                InputProps={{
                  ...InputProps,
                }}
                fullWidth
                label='Select Date Range'
                value={formatDateRangeDisplay(dateRange)}
                onClick={() => setOpenPicker(true)}
                readOnly
              />
            )}
          />
        </Grid>
      )}

      {accessVerify('DASHBOARD_ANALYTICS_FOOD_SELECTION') && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="food-selector"
              options={filteredFoods}
              value={filteredFoods.filter(food => selectedFood.includes(food.foodId))}
              onChange={(event, newValue) => {
                const selectedFoodIds = newValue.map((food) => food.foodId);
                console.log(selectedFoodIds);
                setSelectedFood(selectedFoodIds);
              }}
              getOptionLabel={(option) => option.foodName} // Display foodName as the label
              isOptionEqualToValue={(option, value) => option.foodId === value.foodId} // Compare foodId for equality
              renderInput={(params) => <TextField {...params} label="Select Food" />}
              renderOption={(props, option) => (
                <li {...props} key={`${option.foodId}-${option.foodName}`}> {/* Combine foodId and foodName for a unique key */}
                  <Chip label={option.foodName} />
                </li>
              )}
            />


          </FormControl>
        </Grid>
      )}
    </>
  );
}
