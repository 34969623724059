import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFSwitch, RHFTextField } from '../../../../../components/hook-form';
import { dispatch, useSelector } from '../../../../../redux/store';
import { getSpecificUserData, updateUser } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { userModel } from '../../../../../models/userModel';
import { getInspection } from '../../../../../redux/slices/hrRedux/inspection/inspectionRedux';

export default function UserSalary({ userId }) {
  const { selectedUser, addData } = useSelector((state) => state.user);
  const [selectedObject, setSelectedObject] = useState({});
  const [processedInspectionData, setProcessedInspectionData] = useState([]);

  let inspectionData;
  ({ data: inspectionData } = useSelector((state) => state.inspection));

  useEffect(() => {
    dispatch(getInspection());
  }, []);

  useEffect(() => {
    setProcessedInspectionData(inspectionData?.map((value) => ({ value: value?.id, label: value?.title })));
  }, [inspectionData]);

  useEffect(() => {
    if (userId) dispatch(getSpecificUserData(userId));
  }, [userId]);

  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  useEffect(() => {
    setSelectedObject(selectedUser?.[0]);
    reset(selectedUser?.[0]);
  }, [selectedUser]);

  const methods = useForm({
    defaultValues: userModel(selectedObject),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    try {
      const tempDataObj = { ...selectedUser };
      Object.keys(value).forEach((key) => {
        tempDataObj[key] = value[key] || null;
      });
      dispatch(updateUser(tempDataObj));
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ py: 4, px: 3 }}>
            <Typography variant='h5'>General Salary Details</Typography>
            <Box
              sx={{
                display: 'grid',
                py: 3,
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(3, 1fr)',
                },
              }}
            >
              <Controller
                name='salary_act'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={field.value || ''}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    options={['Central Staff', 'Outlet Staff']}
                    renderInput={(params) => <TextField label='Act' {...params} />}
                  />
                )}
              />

              <RHFTextField name='salary_basic' label='Basic Salary Amount' type={'number'} />
              <RHFTextField name='salary_new_customer_commission' label='New Customer Sale Commission percentage' type={'number'} />
              <RHFTextField name='salary_return_customer_commission' label='Return Customer Sale Commission percentage' type={'number'} />
              <RHFTextField name='salary_uber_customer_commission' label='Return Uber Sale Commission percentage' type={'number'} />
              <RHFTextField name='salary_pickme_customer_commission' label='Return PickMe Sale Commission percentage' type={'number'} />
              <RHFTextField name='salary_workingDaysPerMonth' label='Working Days Per Month' type={'number'} />
              <RHFTextField name='salary_workingHoursPerDay' label='Working Hours Per Day' type={'number'} />
              <RHFTextField name='salary_OtMultiplication' label='OT Payment Times' type={'number'} />
              <RHFTextField name='salary_budgetReliefAllowanceOne' label='Budget Relied Allowance One' type={'number'} />
              <RHFTextField name='salary_budgetReliefAllowanceTwo' label='Budget Relied Allowance Two' type={'number'} />
              <RHFSwitch name='salary_fromLastMonthAllowance' label='Calculate From Last Month Allowances' />

              {/*<RHFTextField name='timeAllowance' label='Time Allowance' />*/}
              {/*<RHFTextField name='rostAbsentDeduction' label='Rost Absent Deduction' />*/}
              {/*<RHFTextField name='lateCheckingWarningCount' label='Late Checking Warning Count' />*/}
              {/*<RHFTextField name='inspectionAllowance' label='Inspection Allowance' />*/}
              {/*<RHFTextField name='weekDayCommission' label='Weekday Commission' />*/}
              {/*<RHFTextField name='weekMinimumDayEarningForCommission' label='Week Minimum Day Earning for Commission' />*/}
              {/*<RHFTextField name='weekendDayCommission' label='Weekend Day Commission' />*/}
              {/*<RHFTextField name='weekendMinimumDayEarningForCommission' label='Weekend Minimum Day Earning for Commission' />*/}
              {/*<RHFSwitch name='inspectionElegible' label='Inspection Allowance Eligible' />*/}
              {/*<RHFSwitch name='timeAllowanceElegible' label='Time Allowance Eligible' />*/}
              {/*<RHFSwitch name='salesBonusElegible' label='Sales Bonus Eligible' />*/}
            </Box>
            <br />
            <Typography variant='h5'>Allowance Details</Typography>
            <br />

            <Stack spacing={2}>
              <Card sx={{ py: 2, px: 3 }}>
                <Typography variant='subtitle1'>Attendance Allowance</Typography>
                <Box
                  sx={{
                    display: 'grid',
                    py: 2,
                    rowGap: 2,
                    columnGap: 2,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(3, 1fr)',
                    },
                  }}
                >
                  <RHFTextField name='salary_attendanceAllowance' label='Attendance Allowance Amount' type={'number'} />
                  <RHFTextField name='salary_timelyAttendanceAllowance' label='Timely Attendance Allowance Amount' type={'number'} />
                </Box>
              </Card>
              <Card sx={{ py: 2, px: 3 }}>
                <Typography variant='subtitle1'>Special Allowance</Typography>
                <Box
                  sx={{
                    display: 'grid',
                    py: 2,
                    rowGap: 2,
                    columnGap: 2,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(3, 1fr)',
                    },
                  }}
                >
                  <RHFTextField name='salary_fixedAllowance' label='Fixed Allowance' type={'number'} />
                  <RHFTextField name='salary_performanceAllowanceAmount' label='Performance Allowance' type={'number'} />
                  <RHFTextField name='salary_fixedAndFuelAllowance' label='Fixed/Fuel Allowance' type={'number'} />
                  <RHFTextField name='salary_loadingUnloadingDrivingAllowance' label='Loading/Unloading/Driving Allowance' type={'number'} />
                  <RHFTextField name='salary_stockInspectionAmount' label='Stock Inspection Allowance' type={'number'} />
                  <RHFTextField name='salary_salesTargetAmount' label='Sales Target Allowance' type={'number'} />
                  <RHFTextField name='salary_documentSubmissionAmount' label='Document Submission Allowance' type={'number'} />
                </Box>
              </Card>

              <Card sx={{ py: 2, px: 3 }}>
                <Typography variant='subtitle1'>Quality Inspection Allowances</Typography>
                <Box
                  sx={{
                    display: 'grid',
                    py: 1,
                    rowGap: 2,
                    columnGap: 2,
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(4, 1fr)',
                    },
                  }}
                >
                {processedInspectionData?.map((value) => {
                  return (
                   <>
                      <RHFTextField name={`salary_inspection_${value?.value}_amount`} label={`${value?.label} Allowance Amount`} type={'number'} />
                   </>
                  );
                })}
                </Box>
              </Card>

            </Stack>

            <Stack spacing={3} alignItems='flex-end' sx={{ mt: 3 }}>
              <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
