import React, { useEffect, useState } from 'react';
import { Button, Container, Drawer, IconButton, TextField, Tooltip } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { fCurrency } from '../../../../utils/formatNumber';
import { getCashFlowSubCategories, getGrnPaymentList, handleGrnPaymentApproval, } from '../../../../redux/slices/financeRedux/cashFlowRedux';
import { Block, CheckCircleOutline, Edit, PictureAsPdf, PlayCircleFilled } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import InvoicePDF from '../../../../sections/@dashboard/invoice/details/InvoicePDF';
import { BASE_URL, COMPANY_DATA_FOR_PDF } from '../../../../config';
import {
  accessVerify,
  capitalize,
  handleNumber,
  removeUnwantedStockBarCodeList,
  safeJSONParse,
  utcMoment,
} from '../../../../utils/common';
import Iconify from '../../../../components/Iconify';
import GrnPaymentDrawer from './grnPayment/grnPaymentDrawer';
import { getPurchaseNotes } from '../../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { BANK_PAYMENT_TYPES, CASHFLOW_PAYMENT_STATUS } from '../../../../utils/constants';

export default function GrnPayments({statusValue=0, statusName=''}) {
  const dataModel = {
    id: null,
    dateTime: null,
    paymentDate: null,
    scheduledDate: null,
    paidDate: null,
    supplierOrderId: null,
    cashbookLedgerId: null,
    bankPaymentType: null,
    referenceNote: null,
    amount: 0,
    locationId: null,
    status: 2,
    addedBy: null,
    approvedBy: null,
    paymentMarkedBy: null,
    linkedDocuments: [],
  };
  const { themeStretch } = useSettings();
  const [rowSelection, setRowSelection] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [grnPaymentList, setGrnPaymentList] = useState([]);
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { selectedLocation } = useSelector((state) => state?.location);

  let tempGrnPaymentList;
  let addDataStatus;
  ({ grnPaymentList: tempGrnPaymentList, addData: addDataStatus } = useSelector((state) => state?.cashFlow));


  let purchaseNoteList;
  ({ data: purchaseNoteList } = useSelector((state) => state?.purchaseNotes));

  useEffect(() => {
    if (selectedLocation?.id) {
      dispatch(getGrnPaymentList(selectedLocation?.id));
    }
  }, [selectedLocation?.id]);

  useEffect(() => {
   dispatch(getPurchaseNotes())
   dispatch(getCashFlowSubCategories())
  }, []);

  useEffect(() => {
    if(addDataStatus?.time){
      dispatch(getGrnPaymentList(selectedLocation?.id));
      dispatch(getPurchaseNotes())
    }
  }, [addDataStatus]);

  useEffect(() => {
    if (tempGrnPaymentList.length > 0) {
      setGrnPaymentList(
        tempGrnPaymentList?.filter(value=> value?.status === statusValue).map((value) => {
          return {
            ...value,
          };
        })
      );
    }
    setIsModelOpen(false);
  }, [tempGrnPaymentList, statusValue]);

  const getItemsForInvoice = (rowData) => {
    if (rowData?.supplierorder_foodsupplierpricing) {
      const parsedRowData = safeJSONParse(rowData?.supplierorder_foodsupplierpricing);
      return parsedRowData?.map((value, index) => {
        const jsonData = safeJSONParse(value?.jsonData);
        const pricePerSupplierUnit = (jsonData?.price / jsonData?.purchaseUnitQty) * jsonData?.storeUnitQty;
        return {
          id: index,
          title: jsonData?.FoodName,
          foodId: value?.foodId,
          description: `Final Price is checked on ${jsonData?.formatted_dateTime}.`,
          quantity: Number(value?.receivedQty) / (Number(value?.storeQty) / Number(value?.supplierQty)),
          storeUnitQty: jsonData?.storeUnitQty + ' ' + jsonData?.unitSymbol,
          price: pricePerSupplierUnit,
        };
      });
    }
  };

  const columns = !grnPaymentList?.[0]
    ? []
    : Object.keys(grnPaymentList?.[0])
      .map((value) => {
        if (['addedBy','approvedBy','paymentMarkedBy','linkedDocuments'].includes(value)) {
          return null;
        } else if (value === 'datetime') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return row.original?.[value] ? utcMoment(row.original?.[value]).format('YYYY-MM-DD').toString() : '-';
            },
          };
        } else if (value === 'paidDate') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return row.original?.[value] ? utcMoment(row.original?.[value]).format('YYYY-MM-DD').toString() : '-';
            },
          };
        } else if (value === 'supplierOrderId') {
          return {
            accessorKey: value,
            header: "GRN Number",
            Cell: ({ cell, row }) => {
              return row.original?.[value];
            },
          };
        } else if (value === 'paymentDate') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return row.original?.[value] ? utcMoment(row.original?.[value]).format('YYYY-MM-DD').toString() : '-';
            },
          };
        } else if (value === 'scheduledDate') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return row.original?.[value] ? utcMoment(row.original?.[value]).format('YYYY-MM-DD').toString() : '-';
            },
          };
        } else if (value === 'bankPaymentType') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return BANK_PAYMENT_TYPES?.find((val) => val.id === Number(row.original[value]))?.label || '-';
            },
          };
        } else if (value === 'amount') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return fCurrency(row.original[value]);
            },
          };
        }  else if (value === 'addedUserName') {
          return {
            accessorKey: value,
            header: "Initiated By"
          };
        }  else if (value === 'status') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return CASHFLOW_PAYMENT_STATUS?.find((value) => value.id === Number(row.original?.status))?.name;
            },
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      .filter((value) => value);

  const handleDownload = async (row) => {
    const selectedPurchaseNote = purchaseNoteList?.find(value=> value?.id === row?.original?.supplierOrderId);
    const urlList = safeJSONParse(selectedPurchaseNote?.linkedDocuments)?.map(value=> value?.url);
    const pdfBlob = await pdf(
      <InvoicePDF
        invoice={{
          invoiceNumber: `GRN-${selectedPurchaseNote?.id}`,
          createDate: utcMoment(selectedPurchaseNote?.date).format('DD MMMM yyyy').toString(),
          dueDate: utcMoment(selectedPurchaseNote?.date)
            .add(selectedPurchaseNote?.supplierNumberOfDaysForPayment || 30, 'days')
            .format('DD MMMM yyyy')
            .toString(),
          status: selectedPurchaseNote?.status,
          discount: 0,
          taxes: 0,
          paidAmount: handleNumber(selectedPurchaseNote?.paidAmount),
          totalPrice: handleNumber(selectedPurchaseNote?.paymentforreceivings) - handleNumber(selectedPurchaseNote?.paidAmount),
          subTotalPrice: handleNumber(selectedPurchaseNote?.paymentforreceivings),
          topic: 'Item Details',
          invoiceFrom: {
            name: COMPANY_DATA_FOR_PDF?.name,
            address: selectedLocation?.address,
            phone: selectedLocation?.contactNumber,
          },
          invoiceTo: {
            name: selectedPurchaseNote?.supplierName,
            address: selectedPurchaseNote?.supplierAddress,
            phone: selectedPurchaseNote?.supplierCompanyPhone,
          },
          items: getItemsForInvoice(selectedPurchaseNote),
          stockCodes: removeUnwantedStockBarCodeList(safeJSONParse(selectedPurchaseNote?.stockCodes)) || [],
          linkedDocuments: urlList?.map(value=> value?.replace("https://storage.googleapis.com/bubblemania/", `${BASE_URL}imageViewer?path=`)) || []
        }}
        paymentVoucher={{
          voucherId: `Voucher_${row.original?.id}`,
          voucherStatus: CASHFLOW_PAYMENT_STATUS?.find((value) => value.id === Number(row.original?.status))?.name,
          voucherCreatedDate: utcMoment(row.original?.datetime).format('DD MMMM yyyy').toString(),
          voucherScheduledDate: utcMoment(row?.original?.scheduledDate).format('DD MMMM yyyy').toString(),
          voucherPaymentMethod: `${row?.original?.cashBookLedger} - ${BANK_PAYMENT_TYPES?.find((val) => val.id === Number(row.original?.bankPaymentType))?.label}`,
          voucherReferenceNumber: row?.original?.referenceNote || '-',
          voucherInitiatedByUser: row?.original?.addedUserName || '-',
          voucherApprovedByUser: row?.original?.approvedByName || '-',
          voucherMarkedBy: row?.original?.paymentMarkedByName || '-',
        }}
      />
    ).toBlob();

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `Invoice-${selectedPurchaseNote?.id}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfUrl);
  };

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [paymentDate, setPaymentDate] = useState('');

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);
  const handleDateChange = (event) => setPaymentDate(event.target.value);

  const handleSubmitPayment = (row) => {
    if (paymentDate) {
      dispatch(handleGrnPaymentApproval({ ...row?.original, status: 5, paymentDate }));
      closeDrawer();
    } else {
      alert("Please enter a payment date.");
    }
  };
  return (
    <Page title={`GRN Payment (${statusName})`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`GRN Payment (${statusName})`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Finance', href: PATH_DASHBOARD.purchasing.root },
            { name: `GRN Payment (${statusName})` },
          ]}
          action={
            statusValue === 2 ? <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
              setIsModelOpen(true);
              setIsAdd(true);
              setSelectedDataObj(dataModel);
            }}>
              Initiate GRN Payment
            </Button> : <></>
          }
        />

        <DataGridTable
          name={'GrnPayment'}
          data={grnPaymentList}
          column={columns}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={true}
          renderRowActionItems={(row) => [
            <>
              {[1,2].includes(row?.original?.status)&& (
                <>
                  {accessVerify('GRN_INITIATE_CONFIRM') && <Tooltip title="Confirm Payment">
                    <IconButton
                      color="success"
                      size="small"
                      onClick={() => {
                        dispatch(handleGrnPaymentApproval({ ...row?.original, status: 3 }));
                      }}
                    >
                      <CheckCircleOutline />
                    </IconButton>
                  </Tooltip>}
                <Tooltip title='Decline Payment'>
                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => {
                      dispatch(handleGrnPaymentApproval({...row?.original, status: 4 }));
                    }}
                  >
                    <Block />
                  </IconButton>
                </Tooltip>
                </>
              )}
              {[3].includes(row?.original?.status) && (
                <><Tooltip title="Mark Payment">
                  <Button
                    color="success"
                    onClick={openDrawer}
                    size="small"
                    startIcon={<CheckCircleOutline fontSize="small" />}
                    sx={{
                      padding: '4px 8px',
                      minWidth: 0,
                      textTransform: 'none',
                      fontSize: '0.875rem',
                    }}
                  >
                    Paid
                  </Button>
                </Tooltip>


                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={closeDrawer}
                  >
                    <div style={{ width: 300, padding: 20 }}>
                      <h2>Enter Payment Date</h2>
                      <TextField
                        label="Payment Date"
                        type="date"
                        fullWidth
                        value={paymentDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={()=>handleSubmitPayment(row)}
                        fullWidth
                      >
                        Submit Payment
                      </Button>
                    </div>
                  </Drawer>
                </>
              )}

              <Tooltip title="Edit Record">
                <IconButton color="warning" onClick={() => {
                  setIsAdd(false);
                  setSelectedDataObj({ ...row?.original,
                    linkedDocuments: safeJSONParse(row?.original?.linkedDocuments)
                  });
                  setIsModelOpen(true);
                }}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {row?.original?.status === 'PROCESSING' ? (
                <Tooltip title="Verify receivings">
                  <IconButton color="success" onClick={() => {
                    setIsModelOpen(true);
                    setIsAdd(false);
                  }}>
                    <PlayCircleFilled />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Download PDF">
                  <IconButton color="error" onClick={() => handleDownload(row)}>
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
              )}
            </>,
          ]}
        />

        <GrnPaymentDrawer isModelOpen={isModelOpen} setIsModelOpen={setIsModelOpen} selectedDataObj={selectedDataObj} setSelectedDataObj={setSelectedDataObj} isAdd={isAdd}/>
      </Container>
    </Page>
  );
}
