export const GLOBAL_LOCATION_ID = 1;

export const leaveStatus = [
  { id: 1, name: 'Pending', color: '#FFC107' },
  { id: 2, name: 'Approved', color: '#00AB55' },
  { id: 3, name: 'Declined', color: '#FF4842' },
];
export const leaveTypes = [
  { id: 1, name: 'Annual Leave' },
  { id: 2, name: 'Sick Leave' },
  // { id: 3, name: 'Maternity Leave' },
  // { id: 4, name: 'Paternity Leave' },
  // { id: 5, name: 'Unpaid Leave' },
  // { id: 6, name: 'Other' },
  { id: 7, name: 'Casual Leave' },
];

export const dayLeaveType = [
  {
    id: 1,
    name: 'Full Day',
    startTime: 'dd/MM/yyyy 00:00:00',
    endTime: 'dd/MM/yyyy 23:59:59',
  },
];
export const CASHFLOW_TYPES = [
  {
    id: 1,
    name: '(Debit) Deposit',
    code: 'DEPOSIT',
    isDebit: true,
    isShow: true,
  },
  {
    id: 2,
    name: '(Debit) POS Sales',
    code: 'POS_SALE_DEPOSIT',
    isDebit: true,
    isShow: false,
  },
  {
    id: 3,
    name: '(Credit) Stock Supplier Payments',
    code: 'STOCK_SUPPLIER',
    isDebit: false,
    isShow: true,
  },
  {
    id: 4,
    name: '(Credit) Asset Supplier Payments',
    code: 'ASSET_SUPPLIER_PAYMENT',
    isDebit: false,
    isShow: true,
  },
  {
    id: 5,
    name: '(Credit) Cash Drawer',
    code: 'CASH_DRAWER',
    isDebit: false,
    isShow: false,
  },
  {
    id: 6,
    name: '(Credit) Other Supplier Payments',
    code: 'OTHER_PAYMENTS',
    isDebit: false,
    isShow: true,
  },
  {
    id: 7,
    name: '(Credit) Service Supplier Payments',
    code: 'ASSET_BASED_SERVICE',
    isDebit: false,
    isShow: true,
  },
  {
    id: 8,
    name: '(Credit) Other Expense Payments',
    code: 'OTHER_EXPENSES',
    isDebit: false,
    isShow: true,
  },
  {
    id: 9,
    name: '(Debit) Supplier Returns',
    code: 'SUPPLIER_RETURNS',
    isDebit: true,
    isShow: true,
  },
];

export const CASHFLOW_PAYMENT_STATUS = [
  {
    id: 1,
    name: 'Pending',
    code: 'PENDING',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: false,
  },
  {
    id: 2,
    name: 'Schedule',
    code: 'SCHEDULED',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: true,
  },
  {
    id: 3,
    name: 'Confirmed',
    code: 'CONFIRMED',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: true,
  },
  {
    id: 4,
    name: 'Declined',
    code: 'DECLINED',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: true,
  },
  {
    id: 5,
    name: 'Paid',
    code: 'PAID',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: false,
  },
];

export const EXPENSE_STATUS_TYPES = [
  {
    id: 1,
    name: 'Pending',
    code: 'PENDING',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: false,
  },
  {
    id: 2,
    name: 'Approved',
    code: 'APPROVED',
    showInitially: true,
    color: 'green',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: true,
  },
  {
    id: 3,
    name: 'Declined',
    code: 'DECLINED',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: true,
  },
  {
    id: 4,
    name: 'Partially Paid',
    code: 'PARTIALLY_PAID',
    showInitially: false,
    color: 'yellow',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: false,
  },
  {
    id: 5,
    name: 'Paid',
    code: 'PAID',
    showInitially: false,
    color: 'green',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: false,
  },
];

export const ASSET_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  BROKEN: 'BROKEN',
};

export const ASSET_PAYMENT_STATUS = {
  PENDING: 'PENDING',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  PAID: 'PAID',
};


export const ORDER_STATUS = {
  DRAFT: {
    id: 1,
    name: 'Draft',
    code: 'DRAFT',
    defaultDescription: 'Delivery Note In Draft Stage.',
  },
  PROCESSING: {
    id: 2,
    name: 'Processing',
    code: 'PROCESSING',
    defaultDescription: 'Waiting for delivery.',
  },
  RECEIVED: {
    id: 3,
    name: 'Received',
    code: 'RECEIVED',
    defaultDescription: 'Item received for this order.',
  },
  PARTIALLY_PAID: {
    id: 4,
    name: 'Partially Paid',
    code: 'PARTIALLY_PAID',
    defaultDescription: 'PAID & Completed',
  },
  PAID: {
    id: 5,
    name: 'Paid',
    code: 'PAID',
    defaultDescription: 'PAID & Completed',
  },
  PENDING_FINANCE_CONFIRMATION: {
    id: 6,
    name: 'Pending Finance Confirmation',
    code: 'PENDING_FINANCE_CONFIRMATION',
    defaultDescription: 'Pending Finance Confirmation',
  },
  DELETED: {
    id: 7,
    name: 'Deleted',
    code: 'DELETED',
    defaultDescription: 'Deleted',
  },
};

export const BANK_PAYMENT_TYPES = [{
  id: 1,
  label: 'Cheque Payment',
},{
  id: 2,
  label: 'Online Payment',
}]

export const HOLIDAY_TYPES = [
  { label: 'OT', value: 'OT' },
  { label: 'Double OT', value: 'DOUBLE_OT' },
];