import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import LocationManagement from '../pages/dashboard/settings/location';
import UserManagementAccount from '../pages/dashboard/hr/userManagement/userAccount';
import TimeLog from '../pages/dashboard/hr/timeLog';
import SalesList from '../pages/dashboard/sales/salesList';
import SalaryManagement from '../pages/dashboard/hr/salary';
import PaySheetIndex from '../pages/dashboard/hr/paysheet/paySheetIndex';
import SupplierManagement from '../pages/dashboard/suppliers/suppliers';
import FoodList from '../pages/dashboard/food/foodList';
import FoodCategoryManagement from '../pages/dashboard/food/foodCategory';
import { dispatch } from '../redux/store';
import { getLocations } from '../redux/slices/settingsManagementRedux/locationsRedux';
import { getUnits } from '../redux/slices/stockManagementRedux/unitRedux';
import EnterPrices from '../pages/dashboard/purchasing/enterPrices';
import AddToCart from '../pages/dashboard/purchasing/addToCart';
import PurchaseNotes from '../pages/dashboard/purchasing/purchaseNotes';
import Receiving from '../pages/dashboard/purchasing/receiving';
import FoodEditor from '../pages/dashboard/food/foodEditor';
import StockList from '../pages/dashboard/stock/inventoryManagement/stockList';
import SingleStockItem from '../pages/dashboard/stock/inventoryManagement/singleStockItem';
import Sender from '../pages/dashboard/stock/transferManagement/sender';
import ViewTransfer from '../pages/dashboard/stock/transferManagement/viewTransfer';
import { SetToCookingStatus } from '../pages/dashboard/kitchen/setToCookingStatus';
import { SetToStoreStatus } from '../pages/dashboard/kitchen/setToStoreStatus';
import { CookingProcess } from '../pages/dashboard/kitchen/cookingProcess';
import KitchenDisplay from '../pages/dashboard/kitchen/kitchenDisplay';
import AssetsList from '../pages/dashboard/assets/assetsList';
import ViewStockOrders from '../pages/dashboard/stock/stockOrder/viewStockOrders';
import CreateStockOrders from '../pages/dashboard/stock/stockOrder/createStockOrders';
import AssetCategories from '../pages/dashboard/assets/assetCategories';
import Cashflow from '../pages/dashboard/finance/cashflow/cashflow';
import PaymentTypes from '../pages/dashboard/finance/paymentTypes';
import CashflowTransfers from '../pages/dashboard/finance/cashflowTransfers';
import StockAvailabilityChecker from '../pages/dashboard/stock/stockAvailability/stockAvailabilityChecker';
import CustomerList from '../pages/dashboard/sales/customerList';
import Rost from '../pages/dashboard/rost/rost';
import InspectionTypeList from '../pages/dashboard/hr/inspection/inspectionTypeList';
import InspectionTemplateList from '../pages/dashboard/hr/inspection/inspectionTemplateList';
import InspectionExamin from '../pages/dashboard/hr/inspection/inspectionExamin';
import LeaveCalender from '../pages/dashboard/hr/userManagement/components/leaveCalender';
import Breaker from '../pages/dashboard/stock/breaker';
import { getPermissions } from '../redux/slices/commonRedux/commonRedux';
import HomeDashboard from '../pages/dashboard/home/home/homeDashboard';
import CashFlowCategories from '../pages/dashboard/finance/cashFlowCategories';
import Aurudu from '../pages/aurudu/aurudu';
import Coupons from '../pages/dashboard/marketing/coupons';
import { accessVerify } from '../utils/common';
import PermissionRequired from '../pages/errorPages/permissionRequired';
import MetaDashboard from '../pages/dashboard/home/metaDashboard';
import WasteManager from '../pages/dashboard/stock/stockManager/wasteManager';
import UsedStockManager from '../pages/dashboard/stock/stockManager/usedStockManager';
import ReturnManager from '../pages/dashboard/stock/stockManager/returnManager';
import PaymentRequired from '../pages/errorPages/paymentRequired';
import MissingStockManager from '../pages/dashboard/stock/stockManager/missingStockManager';
import MisplaceStockList from '../pages/dashboard/stock/inventoryManagement/misplaceStockList';
import GlobalCashbook from '../pages/dashboard/finance/globalCashbook';
import BulkSms from '../pages/dashboard/marketing/bulkSms';
import CashFlowDocumentType from '../pages/dashboard/finance/cashFlowDocumentType';
import StockAvailabilityReport from '../pages/dashboard/stock/stockAvailability/stockAvailabilityReport';
import ExpenseCategories from '../pages/dashboard/expenses/expenseCategories';
import ViewExpenses from '../pages/dashboard/expenses/viewExpenses';
import FinanceConfirmation from '../pages/dashboard/purchasing/financeConfirmation';
import StockQtyChanger from '../pages/dashboard/stock/StockQtyChanger';
import ControlAccounts from '../pages/dashboard/finance/ledger/controlAccounts';
import ReturnManagement from '../pages/dashboard/stock/returnManagement/returnManagement';
import ViewReturns from '../pages/dashboard/stock/returnManagement/viewReturns';
import SaleDepositVerification from '../pages/dashboard/finance/transfer/saleDepositVerification';
import GrnPayments from '../pages/dashboard/finance/transfer/grnPayments';
import GrnToBePaid from '../pages/dashboard/finance/transfer/grnPayment/grnToBePaid';
import StockBalanceChecker from '../pages/dashboard/stock/stockBalancer/stockBalanceChecker';
import StockBalancerReport from '../pages/dashboard/stock/stockBalancer/stockBalancerReport';
import StockBalancerMonitor from '../pages/dashboard/stock/stockBalancer/stockBalancerMonitor';
import ReturnCustomerReport from '../pages/dashboard/sales/returnCustomerReport';
import Holiday from '../pages/dashboard/hr/holiday';
import PurchasingDashboard from '../pages/dashboard/home/purchasing/purchasingDashboard';
import StockAvailabilityReportEmployee
  from '../pages/dashboard/stock/stockAvailability/stockAvailabilityReportEmployee';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getLocations());
    dispatch(getUnits());
  }, []);

  return useRoutes([
    {
      path: 'pay',
      children: [
        {
          path: 'sheet/:token',
          element: <PaySheetIndex />,
        },
      ],
    },
    {
      path: 'aurudu',
      element: <Aurudu />,
    },
    {
      path: 'aurudu/:uuid',
      element: <Aurudu />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        {
          path: 'dash',
          children: [
            { path: 'home',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'purchasing',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <PurchasingDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'stock', element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'kitchen',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'sales',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'marketing',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'hr',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'quality',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'procurement',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'administration',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'finance',element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? accessVerify('PAYMENT_ACTIVE') ? <HomeDashboard /> : <PaymentRequired /> : <PermissionRequired /> },
            { path: 'meta/:type',element: accessVerify('DASHBOARD_META_DASHBOARD') ? accessVerify('PAYMENT_ACTIVE') ? <MetaDashboard /> : <PaymentRequired /> : <PermissionRequired />  },
          ],
        },
        {
          path: 'purchasing',
          children: [
            { path: 'enterPrices', element: accessVerify('CALL_AND_GET_PRICES_PAGE') ? <EnterPrices /> : <PermissionRequired /> },
            { path: 'purchaseNotes', element: accessVerify('PURCHASE_NOTE_PAGE') ? <PurchaseNotes /> : <PermissionRequired /> },
            { path: 'addToCart', element: accessVerify('ADD_TO_CART_PAGE') ? <AddToCart /> : <PermissionRequired /> },
            { path: 'receiving', element: accessVerify('GOODS_RECEIVING_PAGE') ? <Receiving /> : <PermissionRequired /> },
            { path: 'financeConfirmation', element: accessVerify('GOODS_FINANCE_CONFIRMATION') ? <FinanceConfirmation /> : <PermissionRequired /> },
            {
              path: 'suppliers/:supplierType',
              element: accessVerify('STOCK_SUPPLIER_PAGE') ? <SupplierManagement /> : <PermissionRequired />,
            },
            {
              path: 'foods',
              children: [
                { path: 'category/:type', element: accessVerify('FOOD_CATEGORY_PAGE') ? <FoodCategoryManagement /> : <PermissionRequired /> },
                { path: 'types/:type', element: accessVerify('FOOD_PAGE') ? <FoodList /> : <PermissionRequired /> },
                { path: 'food/:type/manage/:foodId', element: accessVerify('FOOD_UPDATE') ? <FoodEditor /> : <PermissionRequired /> },
              ],
            },
          ],
        },
        {
          path: 'suppliers/:supplierType',
          element: accessVerify('STOCK_SUPPLIER_PAGE') ? <SupplierManagement /> : <PermissionRequired />,
        },
        {
          path: 'kitchen',
          children: [
            { path: 'cooking', element: accessVerify('COOKING_STATUS_PAGE') ? <SetToCookingStatus /> : <PermissionRequired /> },
            { path: 'store', element: accessVerify('COOKING_STATUS_PAGE') ? <SetToStoreStatus /> : <PermissionRequired /> },
            { path: 'cookingProcess', element: accessVerify('COOKING_PROCESS_PAGE') ? <CookingProcess /> : <PermissionRequired /> },
            { path: 'display', element: accessVerify('KITCHEN_DISPLAY_PAGE') ? <KitchenDisplay /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'marketing',
          children: [
            { path: 'coupons/:type', element: accessVerify('COUPONS_PAGE') ? <Coupons /> : <PermissionRequired /> },
            { path: 'bulksms', element: accessVerify('SMS_PAGE') ? <BulkSms /> : <PermissionRequired /> },
            {
              path: 'expenses',
              children: [
                { path: 'view/:mainCategory', element: accessVerify('EXPENSE_MARKETING_PAGE') ? <ViewExpenses /> : <PermissionRequired /> },
                { path: 'category/:mainCategory', element: accessVerify('EXPENSE_MARKETING_PAGE') ? <ExpenseCategories /> : <PermissionRequired /> },
              ],
            },
          ],
        },
        {
          path: 'stock',
          children: [
            { path: 'view-all/:showPOSOnly', element: accessVerify('VIEW_STOCK_PAGE') ? <StockList /> : <PermissionRequired /> },
            { path: 'misplaceStockList', element: accessVerify('VIEW_STOCK_PAGE') ? <MisplaceStockList /> : <PermissionRequired /> },
            { path: 'breaker', element: accessVerify('STOCK_BREAKER_PAGE') ? <Breaker /> : <PermissionRequired /> },
            { path: 'qtyChanger', element: accessVerify('STOCK_QTY_CHANGER') ? <StockQtyChanger /> : <PermissionRequired /> },
            {
              path: 'stockManager',
              children: [
                { path: 'returnManager', element: accessVerify('STOCK_RETURN_PAGE') ? <ReturnManager /> : <PermissionRequired /> },
                { path: 'missingManager', element: accessVerify('STOCK_MISSING_PAGE') ? <MissingStockManager /> : <PermissionRequired /> },
                { path: 'wasteManager', element: accessVerify('STOCK_WASTE_MANAGER_PAGE') ? <WasteManager /> : <PermissionRequired /> },
                { path: 'usedStockManager', element: accessVerify('STOCK_USED_MANAGER_PAGE') ? <UsedStockManager /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'transfer',
              children: [
                { path: 'view', element: accessVerify('STOCK_TRANSFER_PAGE') ? <ViewTransfer /> : <PermissionRequired /> },
                { path: 'sender', element: accessVerify('STOCK_SENDER_PAGE') ? <Sender /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'return',
              children: [
                { path: 'view', element: accessVerify('STOCK_RETURN_PAGE') ? <ViewReturns /> : <PermissionRequired /> },
                { path: 'creator', element: accessVerify('STOCK_RETURN_PAGE') ? <ReturnManagement /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'stockOrder',
              children: [
                { path: 'view', element: accessVerify('STOCK_ORDER_LIST_PAGE') ? <ViewStockOrders /> : <PermissionRequired /> },
                { path: 'create', element: accessVerify('CREATE_STOCK_ORDER_LIST_PAGE') ? <CreateStockOrders /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'availability',
              children: [
                { path: 'checker', element: accessVerify('AVAILABILITY_CHECKER_PAGE') ? <StockAvailabilityChecker /> : <PermissionRequired /> },
                { path: 'report', element: accessVerify('SUPER_ADMIN') ? <StockAvailabilityReport /> : <PermissionRequired /> },
                { path: 'reportEmp', element: accessVerify('AVAILABILITY_CHECKER_PAGE') ? <StockAvailabilityReportEmployee /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'stockBalance',
              children: [
                { path: 'checker', element: accessVerify('GENERAL_DATA') ? <StockBalanceChecker /> : <PermissionRequired /> },
                { path: 'report', element: accessVerify('GENERAL_DATA') ? <StockBalancerReport /> : <PermissionRequired /> },
                { path: 'monitor', element: accessVerify('GENERAL_DATA') ? <StockBalancerMonitor /> : <PermissionRequired /> },
              ],
            },
            { path: 'single-stock-item/:id', element: accessVerify('VIEW_STOCK_VIEW_SINGLE_ITEM') ? <SingleStockItem /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'sales',
          children: [
            { path: 'saleList', element: accessVerify('SALES_PAGE') ? <SalesList /> : <PermissionRequired /> },
            { path: 'customerList', element: accessVerify('CUSTOMERS_PAGE') ? <CustomerList /> : <PermissionRequired /> },
            { path: 'returnCustomerReport', element: accessVerify('RETURN_CUSTOMER_REPORT') ? <ReturnCustomerReport /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'hr',
          children: [
            {
              path: 'employees',
              children: [
                { path: 'list/:type', element: accessVerify('USER_LIST_PAGE') ? <UsersList /> : <PermissionRequired /> },
                { path: 'account/:id', element: accessVerify('USER_PROFILE_VIEW') ? <UserManagementAccount /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'salary',
              children: [
                { path: 'timeLog', element: <TimeLog /> },
                { path: 'generateSalary', element: accessVerify('USER_PROFILE_VIEW') ? <SalaryManagement /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'rost',
              children: [{ path: 'manager', element: accessVerify('ROST_MANAGER_PAGE') ? <Rost /> : <PermissionRequired /> }],
            },
            {
              path: 'leave',
              children: [
                { path: 'manager/:id', element: accessVerify('LEAVE_MANAGER_PAGE') ? <LeaveCalender /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'Holiday',
              children: [
                { path: 'view', element: accessVerify('LEAVE_MANAGER_PAGE') ? <Holiday /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'expenses',
              children: [
                { path: 'view/:mainCategory', element: accessVerify('EXPENSE_HR_PAGE') ? <ViewExpenses /> : <PermissionRequired /> },
                { path: 'category/:mainCategory', element: accessVerify('EXPENSE_HR_PAGE') ? <ExpenseCategories /> : <PermissionRequired /> },
              ],
            },
          ],
        },
        {
          path: 'quality',
          children: [
            {
              path: 'inspection',
              children: [
                { path: 'typeList', element: accessVerify('INSPECTION_TYPE_PAGE') ? <InspectionTypeList /> : <PermissionRequired /> },
                {
                  path: 'inspectionTemplateList',
                  element: accessVerify('INSPECTION_TEMPLATE_PAGE') ? <InspectionTemplateList /> : <PermissionRequired />,
                },
                {
                  path: 'inspectionExamin',
                  element: accessVerify('INSPECTION_EXAMIN_PAGE') ? <InspectionExamin /> : <PermissionRequired />,
                },
              ],
            },
            {
              path: 'expenses',
              children: [
                { path: 'view/:mainCategory', element: accessVerify('EXPENSE_QUALITY_PAGE') ? <ViewExpenses /> : <PermissionRequired /> },
                { path: 'category/:mainCategory', element: accessVerify('EXPENSE_QUALITY_PAGE') ? <ExpenseCategories /> : <PermissionRequired /> },
              ],
            },
          ],
        },
        {
          path: 'procurement',
          children: [
            {
              path: 'assets',
              children: [
                { path: 'list', element: accessVerify('ASSETS_LIST_PAGE') ? <AssetsList /> : <PermissionRequired /> },
                { path: 'categories', element: accessVerify('ASSET_CATEGORIES_PAGE') ? <AssetCategories /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'suppliers/:supplierType',
              element: accessVerify('STOCK_SUPPLIER_PAGE') ? <SupplierManagement /> : <PermissionRequired />,
            },
            {
              path: 'expenses',
              children: [
                { path: 'view/:mainCategory', element: accessVerify('EXPENSE_PROCUREMENT_PAGE') ? <ViewExpenses /> : <PermissionRequired /> },
                { path: 'category/:mainCategory', element: accessVerify('EXPENSE_PROCUREMENT_PAGE') ? <ExpenseCategories /> : <PermissionRequired /> },
              ],
            },
          ],
        },
        {
          path: 'administration',
          children: [
            {
              path: 'expenses',
              children: [
                { path: 'view/:mainCategory', element: accessVerify('EXPENSE_ADMINISTRATION_PAGE') ? <ViewExpenses /> : <PermissionRequired /> },
                { path: 'category/:mainCategory', element: accessVerify('EXPENSE_ADMINISTRATION_PAGE') ? <ExpenseCategories /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'suppliers/:supplierType',
              element: accessVerify('STOCK_SUPPLIER_PAGE') ? <SupplierManagement /> : <PermissionRequired />,
            },
          ],
        },
        {
          path: 'finance',
          children: [
            { path: 'cashflow', element: accessVerify('CASHFLOW_PAGE') ? <Cashflow /> : <PermissionRequired /> },
            { path: 'globalCashbook', element: accessVerify('CASHFLOW_PAGE') ? <GlobalCashbook /> : <PermissionRequired /> },
            { path: 'cashflowTransfers', element: accessVerify('CASHFLOW_TRANSFERS_PAGE') ? <CashflowTransfers /> : <PermissionRequired /> },
            { path: 'saleDepositVerification', element: accessVerify('SALES_DEPOSIT_VERIFY') ? <SaleDepositVerification /> : <PermissionRequired /> },
            { path: 'grnPayments', children: [
                { path: 'initiate', element: accessVerify('GRN_PAGE_VIEW') ? <GrnToBePaid/> : <PermissionRequired /> },
                { path: 'confirm', element: accessVerify('GRN_PAGE_VIEW') ? <GrnPayments statusValue={2} statusName={'Initiate & Confirm'}/> : <PermissionRequired /> },
                { path: 'paid', element: accessVerify('GRN_PAGE_VIEW') ? <GrnPayments statusValue={3} statusName={'Mark Payment'}/> : <PermissionRequired /> },
                { path: 'deleted', element: accessVerify('GRN_PAGE_VIEW') ? <GrnPayments statusValue={4} statusName={'Deleted'}/> : <PermissionRequired /> },
                { path: 'finalized', element: accessVerify('GRN_PAGE_VIEW') ? <GrnPayments statusValue={5} statusName={'Paid'}/> : <PermissionRequired /> },
              ]},
            {
              path: 'controllerAccount/:paramMainCategoryId',
              element: accessVerify('LEDGERS_VIEW') ? <ControlAccounts /> : <PermissionRequired />
            },
            {
              path: 'setting',
              children: [
                { path: 'cashflowCategories', element: accessVerify('CASHFLOW_CATEGORIES_PAGE') ? <CashFlowCategories /> : <PermissionRequired /> },
                { path: 'CashFlowDocumentType', element: accessVerify('CASHFLOW_CATEGORIES_PAGE') ? <CashFlowDocumentType /> : <PermissionRequired /> },
                { path: 'paymentTypes', element: accessVerify('PAYMENT_TYPES_PAGE') ? <PaymentTypes /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'expenses',
              children: [
                { path: 'view/:mainCategory', element: accessVerify('EXPENSE_FINANCE_PAGE') ? <ViewExpenses /> : <PermissionRequired /> },
                { path: 'category/:mainCategory', element: accessVerify('EXPENSE_FINANCE_PAGE') ? <ExpenseCategories /> : <PermissionRequired /> },
              ],
            },
          ],
        },

        {
          path: 'settings',
          children: [
            {
              path: 'general',
              children: [{ path: 'locations', element: accessVerify('BUSINESS_LOCATIONS_PAGE') ? <LocationManagement /> : <PermissionRequired /> }],
            },
            {
              path: 'account',
              children: [{ path: 'userAccount/:id', element: <UserManagementAccount /> }],
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const UsersList = Loadable(lazy(() => import('../pages/dashboard/hr/userList')));
const ComingSoon = Loadable(lazy(() => import('../pages/template/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/errorPages/maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/template/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/template/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/errorPages/page500')));
const NotFound = Loadable(lazy(() => import('../pages/errorPages/page404')));
