import React from 'react';

export default function Details({ setCurrentPage }) {
  return (
    <div className='fixedMobileSize'>
      <img src={'https://res.cloudinary.com/cbc2021/image/upload/q_auto:low/gift_hi0vdf.png'} className={'welcome'} alt='welcomePage' />

      <a
        onClick={() => {
          setCurrentPage(3);
        }}
      >
        <img src={'https://res.cloudinary.com/experbot-com/image/upload/w_300/Aurudu/idiriyata_yanna_wp3uza.png'} className={'welcomeButton'} alt='button' />
      </a>
    </div>
  );
}
