import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  averageHolidayPricesForStock: [],
  HolidayMenuData: [],
  HolidayVariationData: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'Holiday',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    // GET HolidayManagement
    getHolidaysSuccess(state, action) {
      state.data = action.payload.data;
    },
    // GET Holiday Menu Data
    getHolidaysMenuSuccess(state, action) {
      state.HolidayMenuData = action.payload.data;
    },
    // GET Holiday Variation Data
    getHolidaysVariationSuccess(state, action) {
      state.HolidayVariationData = action.payload.data;
    },
    // GET HolidayManagement
    getAllHolidaysSuccessAndWindowClose(state, action) {
      window.close();
      state.allData = action.payload.data;
    },
    // GET HolidayManagement
    getAllHolidaysSuccess(state, action) {
      state.allData = action.payload.data;
    },
    // GET HolidayManagement
    getHolidayAveragePricingForStockSuccess(state, action) {
      state.averageHolidayPricesForStock = action.payload.data;
    },

    addOrUpdateHolidays(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getAllHolidaysAndClose() {
  return async () => {
    try {
      const response = await get(`allHoliday`);
      if (response.status) {
        dispatch(slice.actions.getAllHolidaysSuccessAndWindowClose(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHoliday() {
  return async () => {
    try {
      const response = await get(`holiday`);
      if (response.status) {
        dispatch(slice.actions.getHolidaysSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllHolidays() {
  return async () => {
    try {
      const response = await get(`allHoliday`);
      if (response.status) {
        dispatch(slice.actions.getAllHolidaysSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addHoliday(body) {
  return async () => {
    try {
      const response = await post(`Holiday`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateHolidays(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateHoliday(body) {
  return async () => {
    try {
      const response = await put(`Holiday`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateHolidays(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
