import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import DataFilter from './components/filter';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { LineChart } from '@mui/x-charts/LineChart';

export default function PurchasingDashboard() {
  const { themeStretch } = useSettings();
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [globalData, setGlobalData] = useState([]);

  let otherData;
  ({ otherData: otherData } = useSelector((state) => state.home));
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  const getSalesPattern = () => {
    const dates = [...new Set(data.map(item => item.dateOnly))].sort((a, b) => moment(a).diff(moment(b)));
    const foodNames = [...new Set(data.map(item => item.foodName))];

    const series = foodNames.map(foodName => {
      const seriesData = dates.map(date => {
        const sale = data.find(item => item.dateOnly === date && item.foodName === foodName);
        return sale ? sale.costPerUnit : 0;
      });

      return {
        label: foodName,
        data: seriesData,
      };
    });

    return {
      xAxis: dates.map(date => moment(date).format('YYYY-MM-DD')),
      series: series
    };
  };

  const value = getSalesPattern();

  const customize = {
    height: 700,
    legend: { hidden: false },
    margin: { top: 20, bottom: 50, left: 50, right: 20 },
  };

  return (
    <Page title='General: Banking'>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2}>
          <DataFilter data={data} setData={setData} setGlobalData={setGlobalData} setOriginalData={setOriginalData} />

          <Grid item xs={12} md={12}>
            <LineChart
              xAxis={[
                {
                  scaleType: 'point',
                  data: value.xAxis, // Formatted date strings
                  label: 'Date', // Add a label for the x-axis
                },
              ]}
              yAxis={[
                {
                  label: 'Cost Per Unit', // Add a label for the y-axis
                },
              ]}
              series={value.series} // Series data for each food item
              {...customize}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}