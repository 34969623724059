import React, { useEffect, useState } from 'react';
import { Container, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { getPosReturnCustomerReport } from '../../../redux/slices/posRedux/posRedux';
import PermissionRequired from '../../errorPages/permissionRequired';
import { accessVerify, capitalize } from '../../../utils/common';
import { MobileDateRangePicker } from '@mui/lab';
import { endOfDay, format, startOfDay } from 'date-fns';
import moment from 'moment';
import { fCurrency } from '../../../utils/formatNumber';

export default function ReturnCustomerReport() {
  const { themeStretch } = useSettings();
  const [dataList, setDataList] = useState([]);
  const { selectedLocation } = useSelector((state) => state?.location);
  const { returnCustomerSuccess } = useSelector((state) => state.pos);
  const [openPicker, setOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState([startOfDay(new Date()), endOfDay(new Date())]);

  useEffect(() => {
    const startDate = moment(dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    dispatch(getPosReturnCustomerReport(selectedLocation?.id, startDate,endDate));
  }, [dateRange, selectedLocation]);

  useEffect(() => {
    setDataList(returnCustomerSuccess?.map(value=> ({
      ...value,
        totalSale: value?.totalQty * value?.itemPrice
    })));
  }, [returnCustomerSuccess]);

  const formatDateRangeDisplay = ([start, end]) => {
    if (!start || !end) return '';
    return `${format(start, 'dd/MM/yyyy')} - ${format(end, 'dd/MM/yyyy')}`;
  };

  const columns = !dataList?.[0]
    ? [] : Object.keys(dataList?.[0])
      .map((value) => {
        if (['foodId'].includes(value)) {
          return {};
        } else if (['itemPrice', 'totalSale'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return fCurrency(row?.original?.[value])
            },
          };
        }  else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  return (
    <Page title={`Returning Customer Report`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Returning Customer Report`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Sale',
              href: '',
            },
            { name: `Returning Customer Report` },
          ]}
          action={
            <></>
          }
        />
        <br/>
        <MobileDateRangePicker
          open={openPicker}
          onClose={() => setOpenPicker(false)}
          onOpen={() => setOpenPicker(true)}
          value={dateRange}
          onChange={(newValue) => {}}
          onAccept={(newValue) => {
            setDateRange(newValue);
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <TextField
              ref={inputRef}
              {...inputProps}
              InputProps={{
                ...InputProps,
              }}
              fullWidth
              label='Select Date Range'
              value={formatDateRangeDisplay(dateRange)}
              onClick={() => setOpenPicker(true)}
              readOnly
            />
          )}
        />
        {accessVerify('RETURN_CUSTOMER_REPORT') ? (
          <DataGridTable
            name={'Return Customer Report'}
            data={dataList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            enablePinning={false}
            enableRowSelection={false}
            isRowClickable={true}
            onRowClick={(row) => {
              manageModel(row?.original);
            }}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}
        <sub>*මෙම Report එක මගින් පෙන්වන්නේ, එක කෑම එකක් එක පාරකට වඩා order කරපු customer Details  ටිකයි!</sub>
      </Container>
    </Page>
  );
}
