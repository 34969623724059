import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, LinearProgress, LinearProgressProps, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { Controller, useForm } from 'react-hook-form';
import DataGridTable from '../../../components/table/DataGridTable';
import { FormProvider } from '../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { getTimeLogs } from '../../../redux/slices/hrRedux/timeLogRedux';
import { UploadFile } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../config';
import { getUsers } from '../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import DateTimeRangePickerCustomer from '../../../components/DateTimeRangePickerCustomer';
import { capitalize } from '../../../utils/common';

const XLSX = require('xlsx');

export default function TimeLog() {
  const dataModel = {
    locationId: null,
  };

  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [timeLogList, setTimeLogList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { data, addData } = useSelector((state) => state.timeLog);
  let locationData;
  ({ data: locationData } = useSelector((state) => state.location));

  let userData;
  ({ data: userData } = useSelector((state) => state.user));

  useEffect(() => {
    dispatch(getTimeLogs(moment(startDate).format('YYYY-MM-DD HH:mm:ss'), moment(endDate).format('YYYY-MM-DD HH:mm:ss')));
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setProgress(0);
    setTimeLogList(
      data.map((value) => {
        return {
          ...value,
          datetime: moment(value.datetime).utc().format('MMMM Do YYYY, h:mm:ss a'),
          locationId: locationData.find((location) => location.id === value.locationId)?.name,
          userId: userData.find((user) => user.id === value.userId)?.name || 'Unknown',
        };
      })
    );
    setIsModelOpen(false);
  }, [data, locationData, userData]);

  const manageModel = (modelData, type) => {
    setCSVData([]);
    setUploadedFileData(null);
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const defaultValues = useMemo(
    () => ({
      locationId: selectedObject?.locationId || '',
    }),
    [selectedObject]
  );

  const methods = useForm({
    // resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAdd && selectedObject) {
      reset(defaultValues);
    }
    if (!isAdd) {
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const batchSize = 3000;
      const tempDataObj = {
        location: value?.locationId || '',
        logData: csvData,
      };

      const logDataArray = [];

      csvData.forEach((entry) => {
        const { uId, date } = entry;
        logDataArray.push([date, Number(uId), value?.locationId?.id]);
      });

      const logDataLength = logDataArray.length;
      const batches = Math.ceil(logDataLength / batchSize);
      let completedBatches = 0; // Track the number of completed batches

      const promises = [];

      for (let i = 0; i < batches; i++) {
        const start = i * batchSize;
        const end = start + batchSize;
        const batch = logDataArray.slice(start, end);
        const batchDataObj = {
          location: tempDataObj.location,
          logData: batch,
        };

        promises.push(
          new Promise(async (resolve, reject) => {
            let retryCount = 0;
            let success = false;

            while (retryCount < 3 && !success) {
              try {
                const response = await fetch(`${BASE_URL}timeLog`, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
                  },
                  body: JSON.stringify(batchDataObj),
                  method: 'POST',
                });

                const responseValue = await response.json();
                success = true;
                resolve(responseValue);
                setProgress(((completedBatches + 1) / batches) * 100); // Update the progress state on success
                completedBatches++; // Increment the number of completed batches
              } catch (error) {
                retryCount++;
                await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 1 second before retrying
              }
            }

            if (retryCount === 3 && !success) {
              reject(new Error('Max retry attempts reached'));
            }
          })
        );
      }

      await Promise.all(promises).then((value) => {});

      toast.success('Time log added successfully');
      setIsModelOpen(false);
      dispatch(getTimeLogs());
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      toast.error('Error occurred while adding time log');
      console.error(error);
    }
  };

  const columns = !timeLogList?.[0]
    ? []
    : Object.keys(timeLogList?.[0]).map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      });

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedFileData(file);

    const reader = new FileReader();

    reader.onload = () => {
      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0];
      const transformedData = jsonData.slice(1).map((row) => {
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[index];
        });
        return rowData;
      });

      const processedTransformedData = transformedData
        .map((value) => {
          const checkin = moment(value?.Date + ' ' + value?.Time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
          return  { uId: value?.['User ID'], date: checkin };
        });

      setCSVData(processedTransformedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const LinearProgressWithLabel = (props) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );

  return (
    <Page title='Time Log'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Time Log'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Settings',
              href: '',
            },
            { name: 'Time Log' },
          ]}
          action={
            <>
              <DateTimeRangePickerCustomer startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

              <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel(dataModel, 'add')} style={{ minHeight: '55px' }}>
                Add Time Log
              </Button>
            </>
          }
        />

        <DataGridTable
          name={'Time Log'}
          data={timeLogList}
          column={columns}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={false}
          renderRowActionItems={(value, closeMenu) => []}
        />

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Time Log</h1>
          </Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card {...getRootProps({ className: 'dropzone' })} sx={{ p: 3, cursor: 'pointer', textAlign: 'center' }}>
                    <input {...getInputProps()} accept='.xls' />
                    <div>
                      <UploadFile />
                    </div>
                    {isDragActive ? <p>Drop the XLS file here...</p> : <p>Drag and drop a XLS file here, or click to select a file</p>}
                    {uploadedFileData?.name && <p>File selected: {uploadedFileData?.name}</p>}
                  </Card>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Controller
                        name='locationId'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            onChange={(event, newValue) => {
                              field.onChange(newValue);
                            }}
                            options={locationData?.map((value) => {
                              return {
                                ...value,
                                label: value?.name,
                                value: value?.id,
                              };
                            })}
                            renderInput={(params) => <TextField label='Data Location' {...params} />}
                          />
                        )}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LinearProgressWithLabel value={progress} />
                    <LoadingButton type='submit' variant='contained' size='large' loading={isSubmitting} style={{ width: '100%' }}>
                      {isAdd ? 'Add Time Log' : 'Save Changes'}
                    </LoadingButton>
                    <Button color='info' variant='outlined' size='large' loading={isSubmitting} style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
            {csvData?.length > 0 && (
              <Grid container spacing={1} p={15}>
                <Grid item xs={12} md={12}>
                  <DataGridTable
                    name={'CSV Data'}
                    data={csvData}
                    column={
                      !csvData?.[0]
                        ? []
                        : Object.keys(csvData?.[0]).map((value) => {
                            return {
                              accessorKey: value,
                              header: capitalize(value),
                            };
                          })
                    }
                    isLoading={false}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    renderRowActionItems={(value, closeMenu) => []}
                  />
                </Grid>
              </Grid>
            )}
          </FormProvider>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
