import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../../../utils/formatNumber';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
  height: '100%',
  cursor: 'pointer',
}));

// ----------------------------------------------------------------------

DashboardStatBox.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function DashboardStatBox({
  title,
  value,
  icon,
  shortenNumber = true,
  smallText = 'h3',
  isIconVisible = true,
  percentageFromDeposited = false,
  percentageFromGrossSales = false,
  bgColor = 'white',
  titleOnTop = false,
}) {
  const [isShortened, setIsShortened] = useState(shortenNumber);

  const handleToggleNumberFormat = () => {
    setIsShortened((prev) => !prev);
  };

  return (
    <RootStyle style={{ backgroundColor: bgColor }} onClick={handleToggleNumberFormat}>
      <div>
        {titleOnTop && (
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
        )}
        {!isShortened && <Typography variant={smallText}>{value}</Typography>}
        {isShortened && <Typography variant={smallText}>{fShortenNumber(value)}</Typography>}
        {!titleOnTop && (
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
        )}
        {percentageFromDeposited && (
          <Typography variant='p' sx={{ color: 'text.secondary' }}>
            From Deposited : {percentageFromDeposited}%
          </Typography>
        )}
        <br />
        {percentageFromGrossSales && (
          <Typography variant='p' sx={{ color: 'text.secondary' }}>
            From Gross Sale : {percentageFromGrossSales}%
          </Typography>
        )}
      </div>
      {/*{isIconVisible && icon}*/}
    </RootStyle>
  );
}
