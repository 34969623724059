import React, { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../redux/store';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import { useParams } from 'react-router-dom';
import { Container, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Logo from '../../../../components/Logo';
import moment from 'moment';
import { getSalaryById } from '../../../../redux/slices/hrRedux/UserManagementRedux/salaryRedux';
import { safeJSONParse } from '../../../../utils/common';
import { fCurrency } from '../../../../utils/formatNumber';

export default function PaySheetIndex() {
  const { token } = useParams();
  const { themeStretch } = useSettings();
  const [sheetData, setSheetData] = useState({});

  const { data } = useSelector((state) => state.salary);

  useEffect(() => {
    dispatch(getSalaryById(token));
  }, [token]);

  useEffect(() => {
    const tempData = data?.[0]?.data;
    if (tempData) {
      setSheetData({ ...safeJSONParse(tempData), month: data?.[0]?.month });
    }
  }, [data]);

  const handleDownload = () => {
    // const element = document.getElementById("paySheet");
    // html2pdf().set({ filename: "paySheet.pdf" }).from(element).save();
  };

  const styling = {
    amountStyles: {
      fontWeight: '600',
      fontSize: '1.2em',
      textAlign: 'right',
    },
    finalAmountStyles: {
      fontWeight: '800',
      fontSize: '1.5em',
      textAlign: 'right',
    },
  };
  return (
    <Page title='Invoice: View'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/*<Tooltip title="Print" onClick={handleDownload}>*/}
        {/*  <IconButton>*/}
        {/*    <Iconify icon={"eva:download-fill"} />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
        <div id='paySheet' style={{ padding: '20px', width: '100%' }}>
          <Logo sx={{ width: 100, height: 100 }} />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <p>
                  <b>Bubble Mania (PVT) Ltd</b>
                  <br />
                  No. 101/A5,
                  <br />
                  New Kandy Road,
                  <br />
                  Kothalawala,
                  <br />
                  Kaduwela
                </p>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <p>
                  <b>{sheetData?.userData?.name} (EPF Number: {sheetData?.userData?.epfNumber})</b>
                  <br />
                  Designation: {sheetData?.userData?.designation},<br />
                  {sheetData?.userData?.address},<br />
                  {sheetData?.userData?.city},<br />
                  {sheetData?.userData?.phoneNumber}
                  <br />
                  {sheetData?.userData?.email}
                </p>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mb: 5 }}>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '1.5em',
                  textDecoration: 'underline',
                  fontWeight: '700',
                }}
              >
                Salary Pay Sheet for {moment(sheetData?.month).format('MMMM YYYY')}
              </p>

              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Number of Rost working days
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.noOfDaysShouldWork} Days</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Basic Salary
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.userData?.salary_basic)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Budgetery Relief Allowance One
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.budgetReliefAllowance1)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Budgetery Relief Allowance Two
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.budgetReliefAllowance2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Nopay Deduction Amount
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.noPayDeduction?.amount)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Total Basic Salary for EPF/ETF
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.totalBasicSalaryForEPFETF)}</TableCell>
                    </TableRow>

                    <h1>Allowance</h1>
                    { Object.entries(sheetData?.allowance || {}).map(([key, value], index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell style={{ fontWeight: '800' }}>
                            {key} {/* Display the key */}
                          </TableCell>
                          <TableCell style={styling.amountStyles}>
                            {fCurrency(value?.amount || 0)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Total Allowance
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.totalAllowance)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Gross Salary
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.grossSalary)}</TableCell>
                    </TableRow>
                    <h1>Deductions</h1>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        EPF 8%
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.deductions?.epf?.employee)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        PAYE
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.deductions?.payeTax)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Total Deductions
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.deductions?.epf?.employee + sheetData?.deductions?.payeTax)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Net Salary
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.netSalary)}</TableCell>
                    </TableRow>

                    <h1>Company Contribution to EPF & ETF</h1>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        EPF 12%
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.deductions?.epf?.company)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        ETF 3%
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{fCurrency(sheetData?.deductions?.etf?.company)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
}
