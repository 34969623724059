import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { getAvailabilityData } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import DatePicker from '@mui/lab/DatePicker';
import { format } from 'date-fns';
import { accessVerify } from '../../../../utils/common';
import DataGridTable from '../../../../components/table/DataGridTable';
import PermissionRequired from '../../../errorPages/permissionRequired';
import { fCurrency } from '../../../../utils/formatNumber';

const StockAvailabilityReportEmployee = () => {
  const { themeStretch } = useSettings();
  const [data, setData] = useState();
  const [filterStartDate, setFilterStartDate] = useState(new Date());
  const [rowSelection, setRowSelection] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  const onFilterStartDate = (newValue) => {
    setFilterStartDate(newValue);
  };

  useEffect(() => {
    const formattedDate = format(filterStartDate, 'yyyy-MM-dd');

    dispatch(getAvailabilityData(`${formattedDate} 00:00:00`, selectedLocation.id));
  }, [filterStartDate, getAvailabilityData, selectedLocation]);

  const { availabilityReportData: availabilityReportData } = useSelector((state) => state?.stock);

  useEffect(() => {
    if (availabilityReportData) {
      // Group names and count their occurrences
      const groupedData = availabilityReportData.reduce((acc, item) => {
        if (!acc[item.name]) {
          acc[item.name] = { name: item.name, count: 1 };
        } else {
          acc[item.name].count += 1;
        }
        return acc;
      }, {});

      // Convert the grouped data into an array
      const groupedDataArray = Object.values(groupedData);
      setData(groupedDataArray);
    }
  }, [availabilityReportData]);

  useEffect(() => {
    if (data?.length > 0) {
      setTotalPrice(availabilityReportData?.map((value) => value?.costPerCurrentQty)?.reduce((a, c) => a + c) || 0);
    } else {
      setTotalPrice(0);
    }
  }, [data]);

  const columnList = [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'count',
      header: 'Count',
    }
  ];

  return (
    <Page title='Availability Report'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Availability Report'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.transfer.sender }, { name: 'Availability Report' }]}
          action={[]}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <DatePicker label='Start date' value={filterStartDate} onChange={onFilterStartDate} renderInput={(params) => <TextField {...params} fullWidth />} />
          </Grid>
          <Grid item xs={12} md={6}>
            <h1 style={{ textAlign: 'right', color: 'red' }}>{fCurrency(totalPrice)}</h1>
          </Grid>
        </Grid>
        <DataGridTable
          name={`${selectedLocation?.name} - ${format(filterStartDate, 'yyyy-MM-dd')} - Stock Availability Report`}
          data={data}
          column={columnList}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={false}
          isRowClickable={true}
          onRowClick={(value) => {}}
          renderRowActionItems={(value) => []}
        />
      </Container>
    </Page>
  );
};

export default StockAvailabilityReportEmployee;