import { Autocomplete, Box, Grid, Stack, SwipeableDrawer, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../../redux/store';
import { fCurrency } from '../../../../../utils/formatNumber';
import { BANK_PAYMENT_TYPES, CASHFLOW_PAYMENT_STATUS, CASHFLOW_TYPES } from '../../../../../utils/constants';
import { CASH_LEDGER_ID } from '../../../../../config';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { addCashFlows, updateCashFlows } from '../../../../../redux/slices/financeRedux/cashFlowRedux';
import CashFlowImageUploader from '../../cashflow/components/cashflowImageUploader';
import ImageViewer from 'react-simple-image-viewer';
import { imageUpload } from '../../../../../inteceptor';
import { utcMoment } from '../../../../../utils/common';

export default function GrnPaymentDrawer({isAdd, isModelOpen, setIsModelOpen, selectedDataObj, setSelectedDataObj}) {
  let purchaseNoteList;
  ({ data: purchaseNoteList } = useSelector((state) => state?.purchaseNotes));
  let cashFlowSubCategoriesList;
  ({ cashFlowSubCategories: cashFlowSubCategoriesList } = useSelector((state) => state?.cashFlow));
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));
  const { loggedInUser } = useSelector((state) => state?.user);

  const [files, setFiles] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setPaymentTypeList(cashFlowSubCategoriesList?.filter(value=> value?.mainCategoryId === 8));
  }, [cashFlowSubCategoriesList]);

  useEffect(() => {
    setDocumentGallery(selectedDataObj?.linkedDocuments?.map((value) => value));
  }, [selectedDataObj]);

  const updateSelectedObject = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  }

  const handleSubmit = () => {
    if (!selectedDataObj.supplierOrderId) {
      toast.error('GRN is required!');
    } else if (!selectedDataObj.cashbookLedgerId) {
      toast.error('Cashbook Ledger is required!');
    }  else if (!selectedDataObj.referenceNote) {
      toast.error('Reference is required!');
    }  else if (!selectedDataObj.paymentDate) {
      toast.error('Payment Date is required!');
    } else if (!selectedDataObj.scheduledDate) {
      toast.error('Scheduled Date is required!');
    } else if (selectedDataObj.amount === 0) {
      toast.error('Amount is required!');
    } else if (selectedDataObj?.linkedDocuments?.length === 0) {
      toast.error('Related documents needs to be uploaded first!');
    } else {
      const tempUpdatedData = {
        ...selectedDataObj,
        locationId: selectedLocation?.id
      }
      dispatch(isAdd ? addCashFlows(tempUpdatedData) : updateCashFlows(tempUpdatedData));
    }
  };
  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `Finance/Cashflow/Loc_${selectedLocation?.id}/User_${loggedInUser?.id}`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };
  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });
        if (data?.status) {
          setSelectedDataObj((prevState) => ({
            ...prevState,
            linkedDocuments: [...prevState.linkedDocuments, data?.url],
          }));
        }
      } catch (error) {
        console.error(error);
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return <SwipeableDrawer
    anchor={'top'}
    open={isModelOpen}
    onOpen={() => setIsModelOpen(true)}
    onClose={() => setIsModelOpen(false)}
    PaperProps={{
      sx: {
        width: '90%',
        height: '90%',
        marginLeft: '5%',
        marginTop: '3%',
        borderRadius: '10px',
      },
    }}
  >
    <Box sx={{ p: 2 }}>
      <h2>
        <u>
          GRN Payment
        </u>
      </h2>
    </Box>
    <Grid container spacing={1}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <Autocomplete
            onChange={(event, newValue) => { if (isAdd) { updateSelectedObject('supplierOrderId', purchaseNoteList.find((value) => value?.id === newValue?.value)?.id); } }}
            options={purchaseNoteList?.filter(value=> ((Math.round(Number(value?.paymentforreceivings)) - Math.round(Number(value?.paidamount))) > 1) && ['RECEIVED','PAID','PARTIALLY_PAID'].includes(value?.status))?.map((value) => { return { label: `${value?.id}) (${utcMoment(value?.date).format('DD-MM-YYYY')}) - ${value?.supplierName} - ${fCurrency(Number(value?.paymentforreceivings) - Number(value?.paidamount))}`, value: value.id, }; })}
            value={ selectedDataObj.supplierOrderId && purchaseNoteList?.find((val) => val.id === selectedDataObj.supplierOrderId) ? { label: `${selectedDataObj.supplierOrderId}) ${purchaseNoteList?.find((val) => val.id === selectedDataObj.supplierOrderId)?.supplierName} - ${fCurrency(Number(purchaseNoteList?.find((val) => val.id === selectedDataObj.supplierOrderId)?.paymentforreceivings) - Number(purchaseNoteList?.find((val) => val.id === selectedDataObj.supplierOrderId)?.paidamount))}`, value: selectedDataObj.supplierOrderId, } : null }
            renderInput={(params) => <TextField label="Select GRN Details" {...params} />}
            disabled={!isAdd}
          />

          <Autocomplete
            onChange={(event, newValue) => { if (isAdd) { updateSelectedObject('cashbookLedgerId', paymentTypeList.find((value) => value?.id === newValue?.value)?.id); } }}
            options={paymentTypeList?.map((value) => { return { label: `${value?.name}`, value: value.id, }; })}
            value={ selectedDataObj.cashbookLedgerId && paymentTypeList?.find((val) => val.id === selectedDataObj.cashbookLedgerId)?.name }
            renderInput={(params) => <TextField label="Select Cashbook Ledger" {...params} />}
            disabled={!isAdd}
          />
          {(selectedDataObj.cashbookLedgerId !== CASH_LEDGER_ID && selectedDataObj.cashbookLedgerId !== null) &&<>
            <Autocomplete
              onChange={(event, newValue) => {
                if (isAdd) {
                  updateSelectedObject('bankPaymentType', newValue?.id);
                }
              }}
              options={BANK_PAYMENT_TYPES}
              value={selectedDataObj.bankPaymentType && BANK_PAYMENT_TYPES?.find((val) => val.id === Number(selectedDataObj.bankPaymentType))?.label}
              renderInput={(params) => <TextField label="Select Bank Payment Type" {...params} />}
              disabled={!isAdd}
            />
            <TextField
              fullWidth
              type='text'
              label='Reference Note'
              value={selectedDataObj?.referenceNote}
              onChange={(e) => {
                updateSelectedObject('referenceNote', e.target.value);
              }}
              disabled={!isAdd}
            />
          </>}
          <DatePicker
            label='Payment Date'
            value={selectedDataObj?.paymentDate}
            onChange={(newValue) => {
              updateSelectedObject('paymentDate', newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            disabled={!isAdd}
          />
          <Autocomplete
            onChange={(event, newValue) => {
              if (selectedDataObj?.status !== 3) {
                updateSelectedObject('status', CASHFLOW_TYPES.find((value) => value?.id === newValue?.value)?.id);
              }
            }}
            options={CASHFLOW_PAYMENT_STATUS?.filter((value) => (value?.visibleOnAdd && isAdd) || (value?.visibleOnEdit && !isAdd))?.map((value) => ({
              label: `${value?.name}`,
              value: value?.id,
            }))}
            value={{
              label: `${CASHFLOW_PAYMENT_STATUS?.find((value) => value.id === selectedDataObj?.status)?.name || 'Payment Status'}`,
              value: selectedDataObj?.status,
            }}
            renderInput={(params) => <TextField label='Payment Status' {...params} />}
            disabled={true}
          />
          {selectedDataObj?.status === 2 && (
            <DatePicker
              label='Scheduled Date'
              value={selectedDataObj?.scheduledDate}
              onChange={(newValue) => {
                updateSelectedObject('scheduledDate', newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={[2, 3, 4].includes(selectedDataObj?.originalStatus)}
            />
          )}

          {selectedDataObj?.status === 3 && (
            <DatePicker
              label='Paid Date'
              value={selectedDataObj?.paidDate}
              onChange={(newValue) => {
                updateSelectedObject('paidDate', newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              disabled={[3, 4].includes(selectedDataObj?.originalStatus === 3)}
            />
          )}

          <TextField
            fullWidth
            type='number'
            label='Amount'
            value={selectedDataObj?.amount}
            onChange={(e) => {
              if (isAdd) {
                updateSelectedObject('amount', e.target.value);
              }
            }}
            disabled={!isAdd}
          />

          <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%', marginLeft: '8px' }} onClick={()=> handleSubmit()}>
            {isAdd ? 'Add Record' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <CashFlowImageUploader
          files={files}
          setFiles={setFiles}
          handleImageUpload={handleImageUpload}
          documentGallery={documentGallery}
          openImageViewer={openImageViewer}
          isUploading={isUploading}
        />
        {isViewerOpen && <ImageViewer src={documentGallery} currentIndex={currentImage} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />}
      </Grid>
    </Grid>
  </SwipeableDrawer>
}
