import { Box, Grid, IconButton, SwipeableDrawer } from '@mui/material';
import { fCurrency } from '../../../../../utils/formatNumber';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { getAverageWalkingCustomerSaleSpentByCustomer } from './dashboardCalculations';
import { useSelector } from '../../../../../redux/store';

export default function EmployeeMessageDrawer({ data, globalData }) {
  let selectedLocation;
  const [isModelOpen, setIsModelOpen] = useState(false);
  ({ selectedLocation } = useSelector((state) => state.location));
  const [priceDifferenceWithGlobal, setPriceDifferenceWithGlobal] = useState(0);
  const { loggedInUser } = useSelector((state) => state?.user);

  useEffect(() => {
    setPriceDifferenceWithGlobal(getAverageWalkingCustomerSaleSpentByCustomer(data) - getAverageWalkingCustomerSaleSpentByCustomer(globalData));
    if (selectedLocation?.isPosAvailable && ![1, 6].includes(loggedInUser?.id)) {
      setIsModelOpen(true);
    }
  }, [data, selectedLocation]);

  return (
    <>
      <SwipeableDrawer
        anchor={'top'}
        open={isModelOpen}
        onOpen={() => setIsModelOpen(true)}
        onClose={() => setIsModelOpen(false)}
        PaperProps={{
          sx: {
            width: '60%',
            height: '80%',
            marginLeft: '20%',
            marginTop: '5vh',
            borderRadius: '10px',
            position: 'relative',
          },
        }}
      >
        <IconButton
          onClick={() => setIsModelOpen(false)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1300,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={1} padding={2} justifyContent='center' alignItems='center' height='100%'>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              {priceDifferenceWithGlobal > 0 && <img src='https://storage.googleapis.com/bubblemania/WEB_BETA/dashboardGif/happydance.gif' alt='Loading...' style={{ width: '500px' }} />}
              {priceDifferenceWithGlobal < 0 && (
                <img src='https://storage.googleapis.com/bubblemania/WEB_BETA/dashboardGif/99972fba7f9f38377b56d5d56e4b6052.gif' alt='Loading...' style={{ width: '500px' }} />
              )}
              {priceDifferenceWithGlobal === 0 && <img src='https://storage.googleapis.com/bubblemania/WEB_BETA/dashboardGif/8552.gif' alt='Loading...' style={{ width: '500px' }} />}
              <br />
              <br />
              {priceDifferenceWithGlobal > 0 && (
                <h2 style={{ maxWidth: '80%', textAlign: 'center' }}>
                  💪Outlet Walking Customer Average Spent එක Global Walking Customer Average Spent එකට වඩා {fCurrency(priceDifferenceWithGlobal)}ක් උඩින් 🚀 <br />
                  සුපිරි! ඔහොම්මම කරගෙන යන්න!
                </h2>
              )}
              {priceDifferenceWithGlobal === 0 && (
                <h2 style={{ maxWidth: '80%', textAlign: 'center' }}>Global Walking Customer Average Spent Difference එකේ වෙනස 0යි හොදේ! මොන හරි කරපන් average වැඩි වෙන්න!</h2>
              )}
              {priceDifferenceWithGlobal < 0 && (
                <h2 style={{ maxWidth: '80%', textAlign: 'center' }}>
                  🤔මොනවද හුටො කොරන්නේ? <br />
                  ඇයි මේ Outlet Walking Customer Average Spent එක Global Walking Customer Average එකට වඩා අඩුවෙන් යන්නේ? 😕 මොනා හරි කරන්න!
                  <br /> {fCurrency(priceDifferenceWithGlobal)}
                  ක් ම අඩුවෙන් යන්නේ බොල! 😞
                </h2>
              )}
            </Box>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
