import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAuruduCustomer } from '../../../../redux/slices/customerRedux/customerRedux';
import { toast } from 'react-toastify';
import { useSelector } from '../../../../redux/store';

export default function UserInfo({ setCurrentPage, phone, setPhone }) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');

  let auruduCustomerData;
  ({ aurudu: auruduCustomerData } = useSelector((state) => state?.customers));

  useEffect(() => {
    if (auruduCustomerData?.status) {
      setCurrentPage(3);
    }
  }, [auruduCustomerData]);

  const handleAddCustomer = () => {
    if (name?.length === 0) {
      toast.error('Please enter a valid name');
    } else if (phone?.length !== 10) {
      toast.error('Please enter a valid phone number');
    } else {
      dispatch(addAuruduCustomer({ name, phone }));
    }
  };

  return (
    <div className='fixedMobileSize'>
      <img src={'https://res.cloudinary.com/dw4o2gz3v/image/upload/q_auto:low/auruduBubble/Artboard_1_qksgly.png'} className={'userDetailsBlock'} alt='welcomePage' />
      <div className='userData'>
        <input type='text' placeholder='Enter your name' className='userNameInput' value={name} onChange={(e) => setName(e.target.value)} />
        <input type='tel' placeholder='Enter your phone number' className='phoneNumberInput' value={phone} onChange={(e) => setPhone(e.target.value)} />
      </div>
      <a
        onClick={handleAddCustomer}
      >
        <img src={'https://res.cloudinary.com/experbot-com/image/upload/w_300/Aurudu/idiriyata_yanna_wp3uza.png'} className={'welcomeButton'} alt='button' />
      </a>
    </div>
  );
}
