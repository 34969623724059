import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography, Autocomplete } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';
import { addHoliday, getHoliday, updateHoliday } from '../../../redux/slices/stockManagementRedux/holidayRedux';
import utcMoment from 'moment';
import { HOLIDAY_TYPES } from '../../../utils/constants';

export default function Holiday() {
  const dataModel = {
    id: '',
    date: '',
    name: '',
    leaveType: '',
  };

  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [holidayList, setHolidayList] = useState([]);

  const { data, addData } = useSelector((state) => state.holiday);

  useEffect(() => {
    dispatch(getHoliday());
  }, []);

  useEffect(() => {
    setHolidayList(
      data?.map((value) => {
        return {
          ...value,
          date: utcMoment(value?.date)?.format('YYYY-MM-DD'),
        };
      })
    );

    const uniqueCategories = [...new Set(data.map((item) => item.mainCategory))];
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getHoliday());
    }
  }, [addData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !holidayList?.[0]
    ? []
    : Object.keys(holidayList?.[0])
      .map((value) => {
        if (['isVerifier', 'isIncrement', 'locationData', 'userData'].includes(value)) {
          return {};
        } else if (['mainCategory'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ row }) => `${capitalize(row?.original?.mainCategory)}`,
          };
        } else if (['name'].includes(value)) {
          return {
            accessorKey: value,
            header: 'Sub Category',
            Cell: ({ row }) => `${capitalize(row?.original?.name)}`,
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const generateUniqueId = () => {
    return 'id-' + Math.random().toString(36).substr(2, 9);
  };

  const handleSubmit = () => {
    if (selectedDataObj.name.length === 0) {
      toast.error('Name is required!');
    } else if (selectedDataObj.date.length === 0) {
      toast.error('Date is required!');
    } else if (selectedDataObj.leaveType.length === 0) {
      toast.error('Leave Type is required!');
    } else {
      const leaveData = {
        date: selectedDataObj.date,
        name: selectedDataObj.name,
        leaveType: selectedDataObj.leaveType,
      };

      console.log('Data to be inserted:', leaveData);

      if (isAdd) {
        dispatch(addHoliday(leaveData));
      } else {
        dispatch(updateHoliday({ ...leaveData, id: selectedDataObj?.id }));
      }

    }
  };

  return (
    <Page title={`Holiday`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Holiday`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'HR', href: '' },
            { name: `Holiday` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Holiday
            </Button>
          }
        />

        {accessVerify('SUPER_ADMIN') ? (
          <DataGridTable
            name={'Payment Types'}
            data={holidayList}
            column={columns}
            isLoading={false}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Holiday</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Holiday Details
                  </Typography>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Date"
                      value={selectedDataObj?.date || ''}
                      onChange={(e) => updateEditingData('date', e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <Autocomplete
                      options={HOLIDAY_TYPES}
                      value={HOLIDAY_TYPES.find((type) => type.value === selectedDataObj.leaveType) || null}
                      onChange={(event, newValue) => {
                        updateEditingData('leaveType', newValue ? newValue.value : '');
                      }}
                      renderInput={(params) => <TextField {...params} label='Leave Type' />}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                    {isAdd ? 'Add Holiday' : 'Save Changes'}
                  </LoadingButton>
                  <Button
                    color='info'
                    variant='outlined'
                    size='large'
                    style={{ width: '100%' }}
                    onClick={() => {
                      manageModel(dataModel, 'add');
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}