import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Chip, Container, Grid, Paper, Stack, SwipeableDrawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from '../../../../utils/formatNumber';
import { toast } from 'react-toastify';
import { accessVerify, capitalize } from '../../../../utils/common';
import { addStockOrder, getStockOrderItems } from '../../../../redux/slices/stockManagementRedux/stockOrderRedux';
import { getCashFlowCategories } from '../../../../redux/slices/financeRedux/cashFlowCategoriesRedux';
import PermissionRequired from '../../../errorPages/permissionRequired';

export default function CreateStockOrders() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [cartData, setCartData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState(false);
  const [enteredPricing, setEnteredPricing] = useState([]);

  let allLocations;
  let selectedLocation;
  ({ data: allLocations, selectedLocation } = useSelector((state) => state.location));

  const dataModel = {
    requiredDate: '',
    fromLocation: '',
    toLocation: selectedLocation?.id,
  };

  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);

  let itemListForLocation;
  let addData;
  ({ itemsList: itemListForLocation, addData } = useSelector((state) => state.stockOrder));

  useEffect(() => {
    dispatch(getStockOrderItems(selectedLocation?.id));
    dispatch(getCashFlowCategories());
  }, [selectedLocation]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  useEffect(() => {
    setEnteredPricing(
      [...itemListForLocation]
        ?.sort((a, b) => Number(a?.foodCategoryId) - Number(b?.foodCategoryId))
        ?.filter((value) => ['ingredient', 'precooked'].includes(value?.type))
        ?.map((value) => ({
          status: value?.minQty > value?.TotalCurrentQty ? 'Need Attention' : 'Enough Stocks',
          requiredQty: '',
          ...value,
        }))
    );
  }, [itemListForLocation]);

  const updateQuantity = (id, key, qty) => {
    setEnteredPricing((prevEnteredPricing) => prevEnteredPricing.map((value) => (value.FoodLocationID === id ? { ...value, [key]: qty } : value)));
  };

  const manageModel = (type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      if (type === 'add') {
        setIsModelOpen(true);
        setSelectedDataObj(dataModel);
      }
    }
  };

  const onSubmit = async () => {
    if (selectedDataObj?.toLocation === selectedDataObj?.fromLocation) {
      toast.warning("From and To Location can't be same!");
    } else if (selectedDataObj?.toLocation === '') {
      toast.warning('To Location is required!');
    } else if (selectedDataObj?.fromLocation === '') {
      toast.warning('From Location is required!');
    } else if (selectedDataObj?.requiredDate === '') {
      toast.warning('Required Date is required!');
    } else {
      try {
        dispatch(addStockOrder({ cart: cartData, data: selectedDataObj }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      setIsModelOpen(false);
    }
  }, [addData]);

  const columns = !enteredPricing?.[0]
    ? []
    : Object.keys(enteredPricing?.[0])
        .map((key) => {
          if (['locationId', 'FoodLocationID', 'foodId', 'FoodCode', 'foodCategoryId', 'FoodUnitId', 'UnitSymbol', 'UnitName', 'isBeverage', 'isVegetarian', 'isVariation'].includes(key)) {
            return {};
          } else if (key === 'FoodImage') {
            return {
              accessorKey: key,
              header: capitalize(key),
              Cell: ({ cell, row }) => {
                return <img src={row?.original?.FoodImage} style={{ width: '250px' }} />;
              },
            };
          } else if (key === 'status') {
            return {
              accessorKey: key,
              header: 'Status',
              Cell: ({ cell, row }) => {
                return (
                  <Chip
                    key={`${row?.original?.id}_${row?.index}`}
                    label={row?.original?.status}
                    sx={{
                      m: 0.5,
                      backgroundColor: row?.original?.status === 'Need Attention' ? 'red' : 'green',
                      color: 'white',
                    }}
                  />
                );
              },
            };
          } else if (key === 'minQty' || key === 'maxQty' || key === 'TotalCurrentQty') {
            return {
              accessorKey: key,
              header: capitalize(key),
              Cell: ({ cell, row }) => {
                return row?.original?.[key] + ' ' + row?.original?.UnitSymbol;
              },
            };
          } else if (key === 'requiredQty') {
            return {
              accessorKey: key,
              header: capitalize(key),
              Cell: ({ cell, row }) => {
                return (
                  <TextField
                    type='number'
                    label={`Qty in ${row?.original?.UnitSymbol}`}
                    value={row?.original[key] || ''}
                    onChange={(event) => updateQuantity(row?.original.FoodLocationID, key, Number(event.target.value))}
                    onClick={(event) => {
                      event.target.select();
                    }}
                  />
                );
              },
            };
          } else if (key === 'price') {
            return {
              accessorKey: key,
              header: 'Price Per Unit',
              Cell: ({ cell }) => fCurrency(cell.getValue()),
            };
          }
          return {
            accessorKey: key,
            header: capitalize(key),
          };
        })
        ?.filter((value) => value?.accessorKey);

  return (
    <Page title='Create Stock Order'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Create Stock Order'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.root }, { name: 'Create Stock Order' }]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                const cartData = enteredPricing?.filter((value) => value?.requiredQty);
                if (cartData?.length > 0) {
                  setCartData(cartData);
                  manageModel('add');
                } else {
                  toast.warning('Atleast one item should have required qty to create this!');
                }
              }}
            >
              Generate Stock Order
            </Button>
          }
        />
        {accessVerify('CREATE_STOCK_ORDER_LIST_VIEW') ? (
          <DataGridTable
            name={'Create Stock Order'}
            data={enteredPricing}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            enableRowSelection={false}
            enablePinning={false}
          />
        ) : (
          <PermissionRequired />
        )}
        <SwipeableDrawer
          anchor='top'
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Generate Stock Order</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    const locationInfo = allLocations.find((value) => value?.id === newValue?.value);
                    updateEditingData('fromLocation', locationInfo?.id);
                  }}
                  options={allLocations?.map((value) => ({
                    label: `${value.name}`,
                    value: value.id,
                  }))}
                  value={{
                    label: `${allLocations?.find((value) => value.id === selectedDataObj?.fromLocation)?.name || 'Location Not Found'}`,
                    value: selectedDataObj?.locationId,
                  }}
                  renderInput={(params) => <TextField label='From Location' {...params} />}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <TextField fullWidth type={'date'} label='Delivery Required Date' value={selectedDataObj?.requiredDate} onChange={(e) => updateEditingData('requiredDate', e.target.value)} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Minimum Qty</TableCell>
                          <TableCell>Current Qty</TableCell>
                          <TableCell>Requested Qty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartData?.map((value, index) => {
                          return (
                            <TableRow key={`${value?.FoodLocationID}-${value?.FoodName}-${value?.FoodDescription}`}>
                              <TableCell>{value?.FoodLocationID}</TableCell>
                              <TableCell>{value?.FoodName}</TableCell>
                              <TableCell>{value?.FoodDescription}</TableCell>
                              <TableCell>
                                {value?.minQty} {value?.UnitSymbol}
                              </TableCell>
                              <TableCell>
                                {value?.TotalCurrentQty} {value?.UnitSymbol}
                              </TableCell>
                              <TableCell>
                                {value?.requiredQty} {value?.UnitSymbol}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Stack>

              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton onClick={onSubmit} variant='contained' size='large' style={{ width: '100%' }}>
                    Verify & Create Stock Order
                  </LoadingButton>
                  <Button color='info' variant='outlined' size='large' style={{ width: '100%' }} onClick={() => manageModel('add')}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
