import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import DataGridTable from '../../../../../components/table/DataGridTable';
import { getColumnsList } from '../../../finance/cashflow/components/columns';

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 3),
}));

DataTable.propTypes = {
  title: PropTypes.string,
  value: PropTypes.shape({
    dataset: PropTypes.arrayOf(PropTypes.object),
  }),
  icon: PropTypes.element,
  shortenNumber: PropTypes.bool,
};

export default function DataTable({ title, value, icon }) {
  const [rowSelection, setRowSelection] = useState(false);
  return (
    <RootStyle>
      {icon}
      <Typography variant='h6' sx={{ color: 'text.secondary', mb: 2 }}>
        {title}
      </Typography>
      <DataGridTable
        name={'Data Table'}
        data={value}
        column={[...getColumnsList(value)]}
        isLoading={false}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        enableRowSelection={false}
        enableRowActions={false}
        isRowClickable={false}
        onRowClick={(row) => { }}
        renderRowActionItems={(value, closeMenu) => []}
      />
    </RootStyle>
  );
}
